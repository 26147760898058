import { create } from 'zustand';

type FeedUpdateStore = {
	feedupdate: boolean;
	updateFeed: (feedupdate: boolean) => void;
	finishUpdateFeed: () => void;
};

export const useFeedUpdateStore = create<FeedUpdateStore>((set) => ({
	feedupdate: false,
	updateFeed: (feedupdate) => set({ feedupdate }),
	finishUpdateFeed: () => set({ feedupdate: false }),
}));
