import { useContext, useLayoutEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthContext } from '../contexts/Auth/AuthContext';

// PUBLIC
import { Feed } from '../app/Feed';
import { Help } from '../app/Help';
import { TermsOfUse } from '../app/public/TermsOfUse';
import { PrivacyPolicy } from '../app/public/PrivacyPolicy';
import { AboutUs } from '../app/public/AboutUs';
import { FeedLogged } from '../app/private/FeedLogged';

// PRIVATE
import { Notification } from '../app/private/Notification';
import { Tournaments } from '../app/private/Tournaments';
import { Profile } from '../app/private/Profile';
import { MyProfile } from '../app/private/MyProfile';
import { Deposit } from '../app/private/Wallet/Deposit';
import { Withdraw } from '../app/private/Wallet/Withdraw';
import { Wallet } from '../app/private/Wallet';
import { Error404 } from '../app/404';
import { Rules } from '../app/public/Rules';
import { TransparencyPortal } from '../app/public/TransparencyPortal';
import { TournamentById } from '../app/private/TournamentById';

export default function Navigation() {
	const { user } = useContext(AuthContext);

	return (
		<Routes>
			{/* PUBLICS */}
			{!user && <Route path="/" element={<Feed />} />}
			<Route path="/help" element={<Help />} />
			<Route path="/about-us" element={<AboutUs />} />
			<Route path="/termsofuse" element={<TermsOfUse />} />
			<Route path="/privacypolicy" element={<PrivacyPolicy />} />
			<Route path="/rules" element={<Rules />} />
			<Route path="/transparency" element={<TransparencyPortal />} />

			<Route path="*" element={<Error404 />} />
			{/* PRIVADAS */}
			{user && (
				<Route path="/">
					<Route index element={<FeedLogged />} />
					<Route path="tournaments" element={<Tournaments />} />
					<Route
						path="tournaments/:id"
						element={<TournamentById />}
					/>
					<Route path="notifications" element={<Notification />} />
					<Route path="profile" element={<Profile />} />
					<Route path="/my-profile" element={<MyProfile />} />
					<Route path="/wallet" element={<Wallet />} />
					<Route path="/wallet/deposit" element={<Deposit />} />
					<Route path="/wallet/withdraw" element={<Withdraw />} />
				</Route>
			)}
		</Routes>
	);
}
