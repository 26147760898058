export const Checked = () => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.99967 11.332C8.9219 11.332 9.70801 11.007 10.358 10.357C11.008 9.70703 11.333 8.92092 11.333 7.9987C11.333 7.07648 11.008 6.29036 10.358 5.64036C9.70801 4.99036 8.9219 4.66536 7.99967 4.66536C7.07745 4.66536 6.29134 4.99036 5.64134 5.64036C4.99134 6.29036 4.66634 7.07648 4.66634 7.9987C4.66634 8.92092 4.99134 9.70703 5.64134 10.357C6.29134 11.007 7.07745 11.332 7.99967 11.332ZM7.99967 14.6654C7.07745 14.6654 6.21079 14.4904 5.39967 14.1404C4.58856 13.7904 3.88301 13.3154 3.28301 12.7154C2.68301 12.1154 2.20801 11.4098 1.85801 10.5987C1.50801 9.78759 1.33301 8.92092 1.33301 7.9987C1.33301 7.07648 1.50801 6.20981 1.85801 5.3987C2.20801 4.58759 2.68301 3.88203 3.28301 3.28203C3.88301 2.68203 4.58856 2.20703 5.39967 1.85703C6.21079 1.50703 7.07745 1.33203 7.99967 1.33203C8.9219 1.33203 9.78856 1.50703 10.5997 1.85703C11.4108 2.20703 12.1163 2.68203 12.7163 3.28203C13.3163 3.88203 13.7913 4.58759 14.1413 5.3987C14.4913 6.20981 14.6663 7.07648 14.6663 7.9987C14.6663 8.92092 14.4913 9.78759 14.1413 10.5987C13.7913 11.4098 13.3163 12.1154 12.7163 12.7154C12.1163 13.3154 11.4108 13.7904 10.5997 14.1404C9.78856 14.4904 8.9219 14.6654 7.99967 14.6654ZM7.99967 13.332C9.48856 13.332 10.7497 12.8154 11.783 11.782C12.8163 10.7487 13.333 9.48759 13.333 7.9987C13.333 6.50981 12.8163 5.2487 11.783 4.21536C10.7497 3.18203 9.48856 2.66536 7.99967 2.66536C6.51079 2.66536 5.24967 3.18203 4.21634 4.21536C3.18301 5.2487 2.66634 6.50981 2.66634 7.9987C2.66634 9.48759 3.18301 10.7487 4.21634 11.782C5.24967 12.8154 6.51079 13.332 7.99967 13.332Z"
				fill="#1D1B20"
			/>
		</svg>
	);
};
