import { create } from 'zustand';

type ModalBalanceAlertStore = {
	modal: boolean;
	urlAction: string;
	depositSuggestion: number;
	leagues: any[];
	optionsSelect: any[];
	openModal: (modal: boolean) => void;
	closeModal: () => void;
	enrollsInfoModal: (
		urlAction: string,
		depositSuggestion: number,
		leagues: any[],
		optionsSelect: any[]
	) => void;
	clearEnrollsInfoModal: () => void;
};

export const useModalBalanceAlertStore = create<ModalBalanceAlertStore>(
	(set) => ({
		modal: false,
		urlAction: '',
		depositSuggestion: 0,
		leagues: [],
		optionsSelect: [],
		openModal: (modal: any) => set({ modal }),
		closeModal: () => set({ modal: false }),
		enrollsInfoModal: (
			urlAction: string,
			depositSuggestion: number,
			leagues: any[],
			optionsSelect: any[]
		) => set({ urlAction, depositSuggestion, leagues, optionsSelect }),
		clearEnrollsInfoModal: () =>
			set({
				leagues: [],
				optionsSelect: [],
				urlAction: '',
				depositSuggestion: 0,
			}),
	})
);
