import { create } from 'zustand';

type ModalLoginStore = {
	modalLogin: boolean;
	loginUrlAction: string;
	openModalLogin: (modalLogin: boolean, loginUrlAction: string) => void;
	closeModalLogin: () => void;
};

export const useModalLoginStore = create<ModalLoginStore>((set) => ({
	modalLogin: false,
	loginUrlAction: '',
	openModalLogin: (modalLogin, loginUrlAction) =>
		set({ modalLogin, loginUrlAction }),
	closeModalLogin: () => set({ modalLogin: false, loginUrlAction: '' }),
}));
