interface PropsIcon {
	classname?: string;
}

export const Transaction = ({ classname }: PropsIcon) => {
	return (
		<svg
			width={20}
			height={14}
			viewBox="0 0 20 14"
			fill="none"
			className={classname}
			xmlns="http://www.w3.org/2000/svg"
			preserveAspectRatio="none"
		>
			<path
				d="M7.01 8.99857H1C0.45 8.99857 0 9.44857 0 9.99857C0 10.5486 0.45 10.9986 1 10.9986H7.01V12.7886C7.01 13.2386 7.55 13.4586 7.86 13.1386L10.64 10.3486C10.83 10.1486 10.83 9.83857 10.64 9.63857L7.86 6.84857C7.55 6.52857 7.01 6.75857 7.01 7.19857V8.99857ZM12.99 6.78857V4.99857H19C19.55 4.99857 20 4.54857 20 3.99857C20 3.44857 19.55 2.99857 19 2.99857H12.99V1.20857C12.99 0.758566 12.45 0.538566 12.14 0.858566L9.36 3.64857C9.17 3.84857 9.17 4.15857 9.36 4.35857L12.14 7.14857C12.45 7.45857 12.99 7.23857 12.99 6.78857Z"
				fill="#6B6E75"
			/>
		</svg>
	);
};
