import { useContext } from 'react';
import { Layout } from '../../../components/layout';
import { useUserStore } from '../../../store/user';
import { AuthContext } from '../../../contexts/Auth/AuthContext';

import {
	RiWallet3Fill,
	RiRssFill,
	RiCustomerServiceFill,
	RiArrowRightSLine,
	RiDice5Fill,
	RiNotification3Line,
	RiMoneyDollarCircleFill,
	RiPlayCircleFill,
	RiAlignLeft,
	RiAlignJustify,
	RiLoginBoxLine,
	RiHashtag,
} from '@remixicon/react';
import { Deposit } from '../../../components/Icons/Deposit';
import { Withdraw } from '../../../components/Icons/Withdraw';

export const Profile = () => {
	const user = useUserStore((state) => state.user);
	const auth = useContext(AuthContext);

	return (
		<Layout customBG="bg-custom-gray2 h-screen" logoWhite>
			<div className="flex flex-row justify-between pb-4 px-3.5 lg:max-w-screen-xl lg:mx-auto lg:pt-4">
				<div>
					<span className="text-custom-gray5 font-bahnschrift font-semibold">
						{user && user.username}
					</span>
					<a href="/my-profile">
						<div className="flex flew-row items-center text-custom-gray5 font-bahnschrift">
							Meu Perfil
							<RiArrowRightSLine
								size={16}
								color="#f6ab3d"
								className="ml-3"
							/>
						</div>
					</a>
				</div>

				<a
					href={'#.'}
					onClick={() => auth.signOut()}
					className="flex flew-row items-center justify-center cursor-pointer"
				>
					<RiLoginBoxLine
						size={24}
						color="#df1737"
						className="ml-3"
					/>
					<span className="text-primary font-bahnschrift font-semibold pl-2">
						Sair
					</span>
				</a>
			</div>
			<div className="h-0 border-solid border-custom-gray3 opacity-25 border-b col-span-4 px-0 mb-[29px]"></div>
			<div className="px-3.5 lg:max-w-screen-xl lg:mx-auto ">
				<div className="w-full h-auto bg-custom-gray1 rounded-[10px] flex flex-col py-7 px-4 gap-8">
					<a
						href="/wallet"
						className="flex justify-start items-center relative gap-2"
					>
						<RiWallet3Fill
							size={20}
							color="#A3A5AB"
							className="ml-3"
						/>
						<p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-custom-gray5 font-bahnschrift">
							Carteira
						</p>
					</a>
					<a
						href="/wallet/deposit"
						className="flex justify-start items-center relative gap-2"
					>
						<Deposit />

						<p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-custom-gray5 font-bahnschrift">
							Depositar
						</p>
					</a>
					<a
						href="/wallet/withdraw"
						className="flex justify-start items-center relative gap-2"
					>
						<Withdraw />
						<p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-custom-gray5 font-bahnschrift">
							Saque
						</p>
					</a>
				</div>
			</div>
			<div className="w-full px-3.5 h-auto flex flex-col py-7 gap-8 lg:max-w-screen-xl lg:mx-auto ">
				<a
					href="/help"
					className="flex justify-start items-center relative gap-2"
				>
					<RiCustomerServiceFill
						size={20}
						color="#A3A5AB"
						className="ml-3"
					/>
					<p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-custom-gray5 font-bahnschrift">
						Suporte ao cliente
					</p>
				</a>
				<a
					href="/"
					className="flex justify-start items-center relative gap-2"
				>
					<RiRssFill size={20} color="#A3A5AB" className="ml-3" />
					<p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-custom-gray5 font-bahnschrift">
						Feed
					</p>
				</a>
				<a
					href="/tournaments"
					className="flex justify-start items-center relative gap-2"
				>
					<RiDice5Fill size={20} color="#A3A5AB" className="ml-3" />
					<p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-custom-gray5 font-bahnschrift">
						Torneios
					</p>
				</a>
				<a
					href="/notifications"
					className="flex justify-start items-center relative gap-2"
				>
					<RiNotification3Line
						size={20}
						color="#A3A5AB"
						className="ml-3"
					/>
					<p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-custom-gray5 font-bahnschrift">
						Notificações
					</p>
				</a>
			</div>
			<div className="h-0 border-solid border-custom-gray3 opacity-25 border-b col-span-4 px-0 mb-[32px]"></div>
			<div className="w-full px-3.5 h-auto flex flex-col py-7 gap-8 lg:max-w-screen-xl lg:mx-auto ">
				<a
					href="/rules"
					className="flex justify-start items-center relative gap-2"
				>
					<RiAlignJustify
						size={20}
						color="#A3A5AB"
						className="ml-3"
					/>
					<p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-custom-gray5 font-bahnschrift">
						Regras
					</p>
				</a>
				<a
					href="#."
					className="flex justify-start items-center relative gap-2"
				>
					<RiHashtag size={20} color="#A3A5AB" className="ml-3" />
					<p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-custom-gray5 font-bahnschrift">
						1.0.1
					</p>
				</a>
			</div>
		</Layout>
	);
};
