export const Tournaments = () => {
	return (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={`flex-grow-0 flex-shrink-0 w-5 h-5 relative text-custom-white`}
		>
			<g id="control_point" clip-path="url(#clip0_1157_1999)">
				<path
					id="Vector"
					d="M10.5003 6.33464C10.042 6.33464 9.66699 6.70964 9.66699 7.16797V9.66797H7.16699C6.70866 9.66797 6.33366 10.043 6.33366 10.5013C6.33366 10.9596 6.70866 11.3346 7.16699 11.3346H9.66699V13.8346C9.66699 14.293 10.042 14.668 10.5003 14.668C10.9587 14.668 11.3337 14.293 11.3337 13.8346V11.3346H13.8337C14.292 11.3346 14.667 10.9596 14.667 10.5013C14.667 10.043 14.292 9.66797 13.8337 9.66797H11.3337V7.16797C11.3337 6.70964 10.9587 6.33464 10.5003 6.33464ZM10.5003 2.16797C5.90866 2.16797 2.16699 5.90964 2.16699 10.5013C2.16699 15.093 5.90866 18.8346 10.5003 18.8346C15.092 18.8346 18.8337 15.093 18.8337 10.5013C18.8337 5.90964 15.092 2.16797 10.5003 2.16797ZM10.5003 17.168C6.82533 17.168 3.83366 14.1763 3.83366 10.5013C3.83366 6.8263 6.82533 3.83464 10.5003 3.83464C14.1753 3.83464 17.167 6.8263 17.167 10.5013C17.167 14.1763 14.1753 17.168 10.5003 17.168Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1157_1999">
					<rect
						width="20"
						height="20"
						fill="white"
						transform="translate(0.5 0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
