export const Unchecked = () => {
	return (
		<svg
			width="16"
			height="17"
			viewBox="0 0 16 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.99967 15.1654C7.07745 15.1654 6.21079 14.9904 5.39967 14.6404C4.58856 14.2904 3.88301 13.8154 3.28301 13.2154C2.68301 12.6154 2.20801 11.9098 1.85801 11.0987C1.50801 10.2876 1.33301 9.42092 1.33301 8.4987C1.33301 7.57648 1.50801 6.70981 1.85801 5.8987C2.20801 5.08759 2.68301 4.38203 3.28301 3.78203C3.88301 3.18203 4.58856 2.70703 5.39967 2.35703C6.21079 2.00703 7.07745 1.83203 7.99967 1.83203C8.9219 1.83203 9.78856 2.00703 10.5997 2.35703C11.4108 2.70703 12.1163 3.18203 12.7163 3.78203C13.3163 4.38203 13.7913 5.08759 14.1413 5.8987C14.4913 6.70981 14.6663 7.57648 14.6663 8.4987C14.6663 9.42092 14.4913 10.2876 14.1413 11.0987C13.7913 11.9098 13.3163 12.6154 12.7163 13.2154C12.1163 13.8154 11.4108 14.2904 10.5997 14.6404C9.78856 14.9904 8.9219 15.1654 7.99967 15.1654ZM7.99967 13.832C9.48856 13.832 10.7497 13.3154 11.783 12.282C12.8163 11.2487 13.333 9.98759 13.333 8.4987C13.333 7.00981 12.8163 5.7487 11.783 4.71536C10.7497 3.68203 9.48856 3.16536 7.99967 3.16536C6.51079 3.16536 5.24967 3.68203 4.21634 4.71536C3.18301 5.7487 2.66634 7.00981 2.66634 8.4987C2.66634 9.98759 3.18301 11.2487 4.21634 12.282C5.24967 13.3154 6.51079 13.832 7.99967 13.832Z"
				fill="#1D1B20"
			/>
		</svg>
	);
};
