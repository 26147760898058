export const nav = [
	{
		href: '/',
		icon: 'Rss',
		name: 'Feed',
	},
	{
		href: '/tournaments',
		icon: 'Tournaments',
		name: 'Torneios',
	},
	{
		href: '/notifications',
		icon: 'Notification',
		name: 'Notificações',
		toggle: true,
	},
	{
		href: '/profile',
		icon: 'Profile',
		name: 'Mais',
	},
];
