import { useContext, useEffect, useState } from 'react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import TextInput from '../../../ui/TextInput';
import { LoginSchema } from '../../../../types/usersValidation';
import { AuthContext } from '../../../../contexts/Auth/AuthContext';
import { SentryError } from '../../../../types/enums';
import { sentryCapture } from '../../../../helpers/utils';
import { toast } from 'sonner';
import Button from '../../../ui/button';
import { useApi } from '../../../../hooks/useApi';
import Toast from '../../../ui/toast';
import { useNavigate } from 'react-router-dom';

const LoginForms = ({
	loginUrlAction,
	navToRegister,
	onClose,
	navToLoginToken,
	navToForgotPass,
}: any) => {
	const navigate = useNavigate();
	const api = useApi();
	const auth = useContext(AuthContext);
	const [loading, setLoading] = useState<boolean>(false);
	const [login, setLogin] = useState('email');
	const [sendToken, setSendToken] = useState<boolean>(false);

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
		trigger,
		watch,
	} = useForm<z.infer<typeof LoginSchema>>({
		resolver: zodResolver(LoginSchema),
		mode: 'all',
		defaultValues: {
			email: '',
			password: '',
			nick: '',
		},
	});

	const emailValue: any = watch('email');
	const passwordValue: any = watch('password');

	useEffect(() => {
		const validateEmail = async () => {
			const isValid = await trigger('email');
			setSendToken(isValid);
		};

		if (emailValue) {
			validateEmail();
		} else {
			setSendToken(false);
		}
	}, [emailValue, trigger]);

	const handleLogin = async (data: z.infer<typeof LoginSchema>) => {
		setLoading(true);

		try {
			const loginCheck = data?.email
				? data.email.toLocaleLowerCase()
				: data?.nick?.toLocaleLowerCase();
			const login = await auth.signIn(loginCheck!, data.password!);
			if (!login.success) {
				throw new Error(login.message);
			}

			toast(<Toast variant="success">Login efetuado com sucesso.</Toast>);
			onClose();
			if (loginUrlAction !== '') {
				navigate(loginUrlAction);
			}
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'handleLogin', SentryError.Error);
		} finally {
			setLoading(false);
		}
	};

	const handleLoginToken = async (data: any) => {
		setLoading(true);

		try {
			const loginToken = await api.sendTokenLogin(emailValue);

			if (!loginToken?.success) {
				throw new Error(loginToken.message);
			}

			toast(<Toast variant="success">Token enviado com sucesso.</Toast>);
			navToLoginToken(emailValue);
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'handleLoginToken', SentryError.Error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<form
			className="flex-col justify-center"
			onSubmit={handleSubmit(handleLogin)}
		>
			<p className="text-2xl font-bold text-left text-custom-gray2 pb-5 font-bahnschrift">
				Faça login em sua conta
			</p>

			<div>
				{login === 'email' ? (
					<div className="flex flex-col">
						<div className="float-right">
							<Button
								variant="small"
								onClick={() => {
									setLogin('nick');
									reset({ email: '' });
								}}
								className="px-3.5 py-1.5 font-bahnschrift w-40 text-center float-right"
								textCustom="text-sm"
							>
								Entrar com o apelido
							</Button>
						</div>

						<Controller
							control={control}
							name="email"
							render={({ field: { value, onChange } }) => (
								<div className="py-3">
									<TextInput
										type="text"
										placeholder="E-mail"
										value={value ?? ''}
										error={errors?.email?.message}
										onChange={onChange}
										classNameCustom="lowercase"
									/>
								</div>
							)}
						/>
					</div>
				) : (
					<div className="flex flex-col">
						<div className="float-right">
							<Button
								variant="small"
								onClick={() => {
									setLogin('email');
									reset({ nick: '' });
								}}
								className="px-3.5 py-1.5 font-bahnschrift w-40 text-center float-right"
								textCustom="text-sm"
							>
								Entrar com o e-mail
							</Button>
						</div>

						<Controller
							control={control}
							name="nick"
							render={({ field: { value, onChange } }) => (
								<div className="py-3">
									<TextInput
										type="text"
										mask="@***************"
										placeholder="Apelido"
										value={value ?? ''}
										error={errors?.nick?.message}
										onChange={onChange}
									/>
								</div>
							)}
						/>
					</div>
				)}
				<Controller
					control={control}
					name="password"
					render={({ field: { value, onChange } }) => (
						<TextInput
							type="password"
							placeholder="Senha"
							value={value}
							error={errors?.password?.message}
							onChange={onChange}
						/>
					)}
				/>
			</div>
			<span
				onClick={() => {
					navToForgotPass();
				}}
				className="cursor-pointer"
			>
				<p className="text-xs pt-1 pb-3 font-medium text-left text-custom-gray2 font-bahnschrift">
					Esqueceu sua senha ?
				</p>
			</span>

			{login === 'email' && passwordValue === '' && sendToken && (
				<Button
					variant="small"
					isLoading={loading}
					onClick={() => handleLoginToken(emailValue!)}
					className="px-3.5 py-1.5 font-bahnschrift text-center"
					textCustom="text-sm"
				>
					Envie um código de acesso para {emailValue}
				</Button>
			)}

			<div className="flex flex-col">
				<Button
					type="submit"
					isLoading={loading}
					className="px-3.5 py-3 mt-3 font-bahnschrift text-center float-left"
					textCustom="text-sm"
				>
					Entrar
				</Button>
			</div>

			<p className="py-3 w-full text-xs text-center">
				<span className="text-xs font-medium text-center text-custom-gray2 font-bahnschrift">
					Não possui conta?{' '}
				</span>
				<a
					href="#."
					onClick={() => {
						navToRegister();
					}}
				>
					<span className="text-xs font-semibold text-center text-primary font-bahnschrift">
						Cadastre aqui
					</span>
				</a>
			</p>
		</form>
	);
};

export default LoginForms;
