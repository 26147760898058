import Button from '../ui/button';
import ModalDialog from '../Modal/Dialog';
import { useModalDialogFeedStore } from '../../store/modalDialogFeed';
import { ClickActionType, ContentTypes } from '../../types/enums';

const DialogFeed = () => {
	const { modalDialogFeed, data, closeModal } = useModalDialogFeedStore();

	const Dialog = () => (
		<>
			<p className="text-2xl font-bold text-left text-custom-gray2  font-bahnschrift">
				{data.content.title}
			</p>

			<div dangerouslySetInnerHTML={{ __html: data.content.html }} />

			{data.content.urlAction && (
				<Button
					onClick={() => {
						window.open(data.content.urlAction, '_blank');
					}}
					className="px-3.5 py-3 font-bahnschrift text-center"
					textCustom="text-sm"
				>
					Ver mais
				</Button>
			)}
		</>
	);

	const Navigate = () => (
		<iframe
			src={data.content.urlAction}
			className="w-full h-[80vh] pb-6"
			sandbox="allow-scripts allow-same-origin"
			title="WebView"
		/>
	);

	if (!data) {
		return <></>;
	}

	return (
		<ModalDialog
			id={data.content.title}
			open={modalDialogFeed}
			onClose={closeModal}
		>
			<div className="flex flex-col gap-6 ">
				{data.content.clickAction === ClickActionType.DIALOG &&
					data.content.contentType === ContentTypes.ADS && <Dialog />}
				{data.content.clickAction === ClickActionType.DIALOG &&
					data.content.contentType === ContentTypes.NEWS && (
						<Navigate />
					)}
			</div>
		</ModalDialog>
	);
};

export default DialogFeed;
