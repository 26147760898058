export interface ITextInputProps {
	value: string;
	placeholder?: string;
	disabled?: boolean;
}

const TextAreaInput = ({ placeholder, value, disabled }: ITextInputProps) => {
	return (
		<>
			<div className="relative block peer w-full h-28 py-4 px-2.5 rounded-md border appearance-none border-custom-gray3 focus:outline-none focus:ring-0 focus:border-info">
				<label
					htmlFor="floating_filled"
					className={`text-custom-gray3 font-bahnschrift peer-focus:text-custom-gray4 absolute text-sm duration-300 transform -translate-y-4 scale-75 top-3.5 z-10 origin-[0] start-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto`}
				>
					{placeholder}
				</label>

				<textarea
					id="floating_filled"
					className="w-full font-bahnschrift bg-[transparent]"
					value={value}
					disabled={disabled}
				/>
			</div>
		</>
	);
};

export default TextAreaInput;
