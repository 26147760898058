import { RiQuestionnaireFill } from '@remixicon/react';
import { useNavigate } from 'react-router-dom';

declare global {
	interface Window {
		zE?: any;
	}
}

export const helpButton = () => {
	const navigate = useNavigate();
	return (
		<button
			type="button" // onClick={() => window.zE('messenger', 'open')}
			onClick={() => window.zE('webWidget', 'open')}
		>
			<RiQuestionnaireFill
				size={36}
				color="#3a3d44"
				className="p-1 flex-grow-0 flex-shrink-0 relative"
			/>
		</button>
	);
};
