import { headerHeight } from '../helpers/utils';
import Header from './Header';
import { NavBar } from './navbar';
import ZendeskWidget from './ZendeskWidget';
import { useUserProfileStore } from '../store/user';
import { useEnrollContext } from '../contexts/Enroll/EnrollContext';

type LayoutProps = {
	children: React.ReactNode;
	customBG?: string;
	logoWhite?: boolean;
};

export const Layout = ({ children, customBG, logoWhite }: LayoutProps) => {
	const { enroll } = useEnrollContext();
	const userData = useUserProfileStore((state: any) => state.userData);
	const dynamicPaddingBottom =
		enroll.length > 0 ? `${80 + enroll.length * 40}px` : '60px';

	return (
		<div className="flex flex-col min-h-screen">
			<div className={`flex-grow ${headerHeight()} ${customBG || ''}`}>
				<Header logoWhite={logoWhite} />

				{children}
			</div>
			<div style={{ paddingBottom: dynamicPaddingBottom }}>
				<NavBar />
			</div>
			<ZendeskWidget user={userData!} />
		</div>
	);
};
