export const Deposit2 = () => {
	return (
		<svg
			width={8}
			height={16}
			viewBox="0 0 8 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			preserveAspectRatio="none"
		>
			<path
				d="M8 15.0013C8 15.5513 7.55 16.0013 7 16.0013H1C0.45 16.0013 0 15.5513 0 15.0013C0 14.4513 0.45 14.0013 1 14.0013H7C7.55 14.0013 8 14.4513 8 15.0013ZM3 3.99125V11.0013C3 11.5513 3.45 12.0013 4 12.0013C4.55 12.0013 5 11.5513 5 11.0013V3.99125H6.79C7.24 3.99125 7.46 3.45125 7.14 3.14125L4.35 0.36125C4.15 0.17125 3.84 0.17125 3.64 0.36125L0.86 3.14125C0.54 3.45125 0.76 3.99125 1.21 3.99125H3Z"
				fill="#6B6E75"
			/>
		</svg>
	);
};
