import { useState } from 'react';
import { RiPencilFill } from '@remixicon/react';
import { useUserProfileStore } from '../../../../../store/user';
import TextInput from '../../../../../components/ui/TextInput';
import Button from '../../../../../components/ui/button';
import Modal from '../../../../../components/Modal/modal';
import { helpButton } from '../../../../../components/ui/helpButton';
import { sentryCapture } from '../../../../../helpers/utils';
import { SentryError } from '../../../../../types/enums';
import { useApi } from '../../../../../hooks/useApi';
import { toast } from 'sonner';
import Toast from '../../../../../components/ui/toast';
import * as Slider from '@radix-ui/react-slider';
import { useTokenStore } from '../../../../../store/token';
import InputSlider from '../../../../../components/ui/InputSlider';

export const EditWithDraw = () => {
	const api = useApi();
	const { updateUserData } = useUserProfileStore();
	const token = useTokenStore((state: any) => state.token);
	const userData = useUserProfileStore((state: any) => state.userData);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(false);
	const [autoWithdraw, setAutoWithdraw] = useState(
		userData?.autoWithdrawPct || 0
	);

	const handleUpdate = async () => {
		setIsLoading(true);
		const body = {
			autoWithdrawPct: autoWithdraw,
		};

		try {
			const response = await api.updateAutoWithdraw(body, token);
			if (!response?.success) {
				throw new Error(response.message);
			}

			const messageSuccess =
				body?.autoWithdrawPct > 0
					? `Auto saque atualizado para ${body?.autoWithdrawPct}% de cada prêmio`
					: `Auto saque de prêmio desligado`;

			updateUserData('autoWithdrawPct', autoWithdraw);
			setOpenModal(!openModal);
			toast(<Toast variant="success">{messageSuccess}!</Toast>);
			window.location.reload();
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'editPixKey', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="flex items-center gap-4">
			<div className="flex-1 ">
				<InputSlider
					placeholder="Auto Saque"
					value={autoWithdraw}
					disabled
				/>
			</div>

			<Button
				onClick={() => setOpenModal(!openModal)}
				variant="help"
				className={'w-12 h-12'}
			>
				<RiPencilFill size={24} color="#3A3D44" className={''} />
			</Button>

			<Modal
				id="withdraw"
				open={openModal}
				onClose={() => setOpenModal(!openModal)}
			>
				<div className="flex flex-col gap-6">
					<p className="text-2xl font-bold text-left text-custom-gray2 w-60 font-bahnschrift">
						Atualizar auto-saque de Prêmio
					</p>

					<div className="flex flex-col rounded-lg border border-custom-gray5 px-2 py-3">
						<p className="text-xs text-center text-custom-gray3 font-bahnschrift">
							Auto saque dos prêmios ({autoWithdraw}%)
						</p>
						<div>
							<Slider.Root
								className="SliderRoot"
								defaultValue={[autoWithdraw]}
								max={100}
								step={1}
								onValueChange={(value) => {
									setAutoWithdraw(value);
								}}
							>
								<Slider.Track className="SliderTrack">
									<Slider.Range className="SliderRange" />
								</Slider.Track>
								<Slider.Thumb aria-label="Volume" />
							</Slider.Root>
						</div>
					</div>

					<Button
						onClick={handleUpdate}
						isLoading={isLoading}
						className="px-3.5 py-3 font-bahnschrift text-center"
						textCustom="text-sm"
					>
						Atualizar
					</Button>

					<div className="flex flex-row justify-between items-center">
						{helpButton()}
					</div>
				</div>
			</Modal>
		</div>
	);
};
