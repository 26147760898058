import { useEffect, useRef, useState } from 'react';

import { RiCheckLine, RiPencilFill } from '@remixicon/react';
import { useUserProfileStore } from '../../../../store/user';
import TextInput from '../../../../components/ui/TextInput';
import Button from '../../../../components/ui/button';
import Modal from '../../../../components/Modal/modal';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { UpdatePhone } from '../../../../types/userData/validation';
import { helpButton } from '../../../../components/ui/helpButton';
import { sentryCapture } from '../../../../helpers/utils';
import { SentryError } from '../../../../types/enums';
import { useApi } from '../../../../hooks/useApi';
import { toast } from 'sonner';
import Toast from '../../../../components/ui/toast';
import { useTokenStore } from '../../../../store/token';

export const PhoneUpdate = ({ open, onClose }: any) => {
	const api = useApi();
	const token = useTokenStore((state: any) => state.token);
	const { updateUserData } = useUserProfileStore();
	const [isLoading, setIsLoading] = useState(false);
	const [code, setCode] = useState('');
	const [activeStep, setActiveStep] = useState(1);
	const step2 = useRef<HTMLInputElement>(null);

	const [timeLeft, setTimeLeft] = useState(120);
	const [canResend, setCanResend] = useState(false);

	useEffect(() => {
		if (timeLeft > 0) {
			const timer = setInterval(() => {
				setTimeLeft(timeLeft - 1);
			}, 1000);

			return () => clearInterval(timer);
		} else {
			setCanResend(true);
		}
	}, [timeLeft]);

	const steps = [
		{ step: 1, title: 'Informe o novo número de celular' },
		{ step: 2, title: 'Confirme o código de verificação' },
	];

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
		watch,
	} = useForm<z.infer<typeof UpdatePhone>>({
		resolver: zodResolver(UpdatePhone),
		mode: 'all',
		defaultValues: {
			phone: '',
		},
	});

	const phoneValue: any = watch('phone');

	useEffect(() => {
		if (activeStep === 2 && step2.current) {
			step2.current.focus();
		}
	}, [activeStep]);

	useEffect(() => {
		if (!open) {
			reset({ phone: '' });
			setCode('');
			setActiveStep(1);
		}
	}, [open]);

	const validateNewPhoneNumber = async (step: number, phone: string) => {
		setIsLoading(true);
		try {
			const verifyPhone = await api.validateNewPhoneNumber(phone);
			if (!verifyPhone?.success) {
				throw new Error(verifyPhone.message);
			}
			setActiveStep(step + 1);
			sendTokenPhone();
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'sendTokenValidation', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	const sendTokenPhone = async () => {
		try {
			const response = await api.sendTokenPhone(phoneValue);

			if (!response?.success) {
				throw new Error(response.message);
			}
			setTimeLeft(120);
			setCanResend(false);
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'sendTokenPhone', SentryError.Error);
		}
	};

	const handleUpdate = async (data: z.infer<typeof UpdatePhone>) => {
		if (code === '' || code.trim() === '') {
			toast(
				<Toast variant="error">Informe o token para continuar.</Toast>
			);
			return;
		}
		if (code.length < 5) {
			toast(<Toast variant="error">Informe um código válido.</Toast>);
			return;
		}
		setIsLoading(true);
		try {
			const verifyToken = await api.validatePhoneToken(phoneValue, code);
			if (!verifyToken?.success) {
				throw new Error(verifyToken.message);
			}

			const response = await api.updatePhone(data, token);
			if (!response?.success) {
				throw new Error(response.message);
			}
			updateUserData('phone', data.phone);
			reset({ phone: '' });
			setCode('');
			setActiveStep(1);
			toast(
				<Toast variant="success">Celular alterado com sucesso!</Toast>
			);
			onClose();
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'sendTokenValidation', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	const stepForm = (step: number) => {
		switch (step) {
			case 1:
				return (
					<Controller
						control={control}
						name="phone"
						render={({ field: { value, onChange } }) => (
							<div className="py-3">
								<TextInput
									mask="(99) 9 9999-9999"
									type="text"
									placeholder="Novo número de celular"
									value={value}
									error={errors?.phone?.message}
									onChange={onChange}
								/>
								<Button
									onClick={() => {
										validateNewPhoneNumber(step, value);
									}}
									disabled={!value || !!errors?.phone}
									isLoading={isLoading}
									className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
									textCustom="text-sm"
								>
									Próximo
								</Button>
							</div>
						)}
					/>
				);
			case 2:
				return (
					<div>
						<p className="text-xs text-left text-custom-gray3 pb-2 font-bahnschrift">
							<span className="text-xs text-left text-custom-gray3 font-bahnschrift">
								Enviamos um código de verificação para o número
								de celular:{' '}
							</span>
							<span className="text-xs font-semibold text-left text-custom-gray3 font-bahnschrift">
								{phoneValue}
							</span>
						</p>

						<TextInput
							type="text"
							placeholder="Digite o código"
							value={code}
							onChange={(e) => {
								setCode(e);
							}}
							ref={step2}
							autoFocus
						/>

						<p className="text-sm text-left">
							<span className="text-xs text-left text-custom-gray3 font-bahnschrift">
								Não recebeu o código?{' '}
							</span>

							{canResend ? (
								<span
									onClick={() => sendTokenPhone()}
									className="text-xs font-semibold text-left text-primary cursor-pointer"
								>
									Clique aqui para reenviar
								</span>
							) : (
								<span className="text-xs font-semibold text-left text-primary cursor-pointer">
									Reenvie em {Math.floor(timeLeft / 60)}:
									{('0' + (timeLeft % 60)).slice(-2)}
								</span>
							)}
						</p>

						<div className="flex gap-2">
							<Button
								variant="destructive"
								onClick={() => setActiveStep(step - 1)}
								className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
								textCustom="text-sm"
							>
								Voltar
							</Button>
							<Button
								type="submit"
								isLoading={isLoading}
								className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
								textCustom="text-sm"
							>
								Confirmar
							</Button>
						</div>
					</div>
				);
			default:
				return null;
		}
	};

	return (
		<div className="flex items-center gap-4">
			<Modal
				id="updatephone"
				open={open}
				onClose={() => {
					onClose();
				}}
			>
				<div className="">
					<p className="text-2xl font-bold text-left text-custom-gray2 pb-5 font-bahnschrift">
						Atualizar celular
					</p>

					<form
						onSubmit={handleSubmit(handleUpdate)}
						className="mb-7 flex flex-col"
					>
						<ol className="relative mx-4">
							{steps.map((item, index) => (
								<li
									key={index}
									className="relative flex flex-col"
								>
									<div className="flex items-start relative">
										<div className="flex flex-col items-center -start-4">
											<span
												className={`absolute z-10 flex items-center justify-center font-bahnschrift w-7 h-7 rounded-full ring-custom-white ${
													item.step <= activeStep
														? 'bg-primary text-custom-white'
														: 'bg-custom-gray5 text-custom-gray2'
												}`}
											>
												{item.step < activeStep ? (
													<RiCheckLine
														size={20}
														color="#ffffff"
														className=""
													/>
												) : (
													item.step
												)}
											</span>
											<div
												className={`text-gray-500 flex border-s w-[1px] absolute ${
													item.step < activeStep
														? 'border-primary h-full -z-10'
														: activeStep ===
																item.step &&
														  activeStep <
																steps.length
														? 'border-primary h-full'
														: item.step <=
																steps.length -
																	1 &&
														  'border-custom-gray5 h-10'
												}`}
											></div>
										</div>

										<div className="flex-1 ml-8 mb-4">
											<h3 className="text-md text-left text-custom-gray2 font-bahnschrift">
												{item.title}
											</h3>
											<div
												className={`${
													item.step !== activeStep
														? 'hidden'
														: ''
												}`}
											>
												{stepForm(item.step)}
											</div>
										</div>
									</div>
								</li>
							))}
						</ol>
					</form>

					<div className="flex flex-row justify-between items-center">
						{helpButton()}
					</div>
				</div>
			</Modal>
		</div>
	);
};
