import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import * as LiveUpdates from '@capacitor/live-updates';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { Toaster } from 'sonner';

import Navigation from './Router/routes';
import { sentryCapture } from './helpers/utils';
import { SentryError } from './types/enums';
import { AuthProvider } from './contexts/Auth/AuthProvider';
import { StreamProvider } from './contexts/GetStream/StreamContext';
import '@ionic/react/css/core.css';
import { setupIonicReact } from '@ionic/react';
import OneSignal from 'onesignal-cordova-plugin';
import OneSignalWeb from 'react-onesignal';
import AppUrlListener from './components/AppUrlListener';
import { PromoProvider } from './contexts/PromoCode/PromoContext';
import { EnrollProvider } from './contexts/Enroll/EnrollContext';

setupIonicReact();

export default function App() {
	const platform = Capacitor.getPlatform();

	const runOneSignalWeb = async () => {
		OneSignalWeb.Debug.setLogLevel('6');
		OneSignalWeb.init({
			appId: '8354282b-7378-4252-b706-c96020faf35f',
		});
	};

	const initializeOneSignal = async () => {
		if (Capacitor.isNativePlatform()) {
			OneSignal.Debug.setLogLevel(6);
			OneSignal.initialize('8354282b-7378-4252-b706-c96020faf35f');
			OneSignal.Notifications.addEventListener('click', async (e) => {
				let clickData = await e.notification;
				console.log('Notification Clicked : ' + clickData);
			});
			OneSignal.Notifications.requestPermission(true).then(
				(success: Boolean) => {
					console.log('Notification permission granted ' + success);
				}
			);
		} else {
			runOneSignalWeb();
		}
	};

	const initializeApp = async () => {
		if (Capacitor.isNativePlatform()) {
			try {
				const result = await LiveUpdates.sync();
				if (result.activeApplicationPathChanged) {
					await LiveUpdates.reload();
				} else {
					await SplashScreen.hide();
				}
			} catch {
				sentryCapture(
					'Error Live Update',
					'Live-Updates',
					SentryError.Error
				);
			}
			if (platform === 'android') {
				document.documentElement.classList.add('android');
			} else if (platform === 'ios') {
				document.documentElement.classList.add('ios');
			}
		} else {
			console.log('Live Update not working.');
		}
	};

	useEffect(() => {
		initializeOneSignal();
		initializeApp();
	}, []);

	return (
		<BrowserRouter>
			<AppUrlListener />
			<Toaster
				richColors
				position="top-center"
				toastOptions={{ duration: 3000, unstyled: true }}
				className="mt-11"
			/>
			<PromoProvider>
				<AuthProvider>
					<StreamProvider>
						<EnrollProvider>
							<Navigation />
						</EnrollProvider>
					</StreamProvider>
				</AuthProvider>
			</PromoProvider>
		</BrowserRouter>
	);
}
