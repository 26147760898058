import {
	createContext,
	useState,
	useEffect,
	useContext,
	ReactNode,
} from 'react';
import { connect } from 'getstream';
import { useTokenGetStreamStore } from '../../store/token';
import { useUserStore } from '../../store/user';
import { useBalanceStore } from '../../store/balance';
import { formatBalance, sentryCapture } from '../../helpers/utils';
import { useNotificationStore } from '../../store/notification';
import { StreamChat } from 'stream-chat';
import { SentryError } from '../../types/enums';
import { useApi } from '../../hooks/useApi';

export const StreamContext = createContext({
	feed: [],
	loading: true,
	error: null,
	updateFeed: false,
	updateFeedFinish: () => {},
});

export const useStream = () => useContext(StreamContext);

const apiKey = 'efe24uu7hbbc';
const userId = '1322086';
export const clientChat = StreamChat.getInstance(apiKey);

export const StreamProvider = ({ children }: { children: ReactNode }) => {
	const api = useApi();
	const tokenGetStream = useTokenGetStreamStore(
		(state) => state.tokenGetStream
	);

	const user = useUserStore((state) => state.user);
	const notificationCount = useNotificationStore(
		(state) => state.notification
	);
	const { saveBalance } = useBalanceStore();
	const { saveNotification } = useNotificationStore();
	const [feed, setFeed] = useState<any>([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [updateFeed, setUpdateFeed] = useState(false);

	useEffect(() => {
		if (!user) {
			return;
		}

		const client = connect(apiKey, tokenGetStream, userId);
		const wallet = client.feed('wallet', user?.accountId);
		const handleWalletUpdate = (data: any) => {
			const newBalance = JSON.parse(data.new[0].object).balance;
			saveBalance(formatBalance(newBalance).toFixed(2));
		};
		wallet.subscribe(handleWalletUpdate);

		const connectChat = async () => {
			try {
				await clientChat.connectUser(
					{ id: user?.accountId! },
					tokenGetStream
				);
				const chat = clientChat.channel(
					'news',
					`news-${user.accountId}`
				);
				await chat.watch();
				chat.on((event) => {
					if (event.type === 'message.new') {
						const newNotifications = 1;
						saveNotification(newNotifications);
					}
				});
			} catch (err: any) {
				sentryCapture(err, 'notification', SentryError.Error);
				setError(err);
				setLoading(false);
			}
		};
		connectChat();
		return () => {
			setFeed([]);
			setLoading(true);
			setError(null);
		};
	}, [
		user,
		tokenGetStream,
		notificationCount,
		saveBalance,
		saveNotification,
	]);

	useEffect(() => {
		const findToken = async () => {
			const response = await api.getTokenFeed();
			if (!response?.success) {
				throw new Error(response.message);
			}

			const { token } = response;

			const handleFeedUpdate = (data: any) => {
				const update = JSON.parse(data.new[0].object).update;

				if (update) {
					console.log('Atualizou o feed!');
					setUpdateFeed(update);
				}
			};

			const clientFeed = connect(apiKey, null, userId);
			const feedUpdate = clientFeed.feed('home', 'system', token);
			feedUpdate.subscribe(handleFeedUpdate);
		};
		findToken();
	}, []);

	useEffect(() => {
		if (user) {
			const findNotification = async () => {
				try {
					await clientChat.connectUser(
						{ id: user?.accountId! },
						tokenGetStream
					);
					const chat = clientChat.channel(
						'news',
						`news-${user.accountId}`
					);
					await chat.watch();
					const notificationCount = chat.countUnread();
					saveNotification(notificationCount);
					await chat.disconnected;
				} catch (err: any) {
					sentryCapture(err, 'notification', SentryError.Error);
					setError(err);
					setLoading(false);
				}
			};
			findNotification();
		}
	}, [user]);

	const updateFeedFinish = async () => {
		setUpdateFeed(false);
	};

	return (
		<StreamContext.Provider
			value={{ feed, updateFeed, loading, error, updateFeedFinish }}
		>
			{children}
		</StreamContext.Provider>
	);
};
