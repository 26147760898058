import React from 'react';
import { getVariant, variantProp } from '../../helpers/variants';
import { RiRestartLine } from '@remixicon/react';

type ButtonProps = {
	children: React.ReactNode;
	type?: 'submit' | 'reset' | 'button' | undefined;
	className?: string;
	onClick?: () => void;
	variant?: variantProp;
	textCustom?: string;
	disabled?: boolean;
	isLoading?: boolean;
};

const Button = ({
	children,
	type = 'button',
	onClick,
	variant = '',
	className,
	textCustom,
	disabled,
	isLoading,
}: ButtonProps) => {
	return (
		<button
			type={type}
			disabled={disabled || isLoading}
			onClick={onClick}
			className={`cursor-pointer ${className} ${getVariant(
				disabled ? 'disabled' : variant
			)}`}
		>
			{isLoading ? (
				<div className="flex items-center justify-center">
					<RiRestartLine className="mr-2 h-4 w-4 animate-spin" />
				</div>
			) : (
				<p
					className={`items-center justify-center flex font-bahnschrift ${textCustom}`}
				>
					{children}
				</p>
			)}
		</button>
	);
};

export default Button;
