import { useState, memo, useRef, forwardRef, useEffect } from 'react';
import * as Slider from '@radix-ui/react-slider';
import { RiCheckLine } from '@remixicon/react';
import { toast } from 'sonner';
import Button from '../../ui/button';
import { Unchecked } from '../../Icons/Unchecked';
import { Checked } from '../../Icons/Checked';
import { PlayersPositionDisplay } from '../../../types/enums';
import { OptionsTournament, Player } from '../../../types/types';
import PlayersSelect from '../../Modal/PlayersSelect';
import Toast from '../../ui/toast';
import { transformInitial } from '../../../helpers/utils';
import { useEnrollContext } from '../../../contexts/Enroll/EnrollContext';

interface OptionItemProps {
	item: any;
	index: number;
	playersTeams: any;
	optionsSelect: any;
	blockSelection: boolean;
	onChange: (id: any) => void;
}

const OptionItem = memo(
	forwardRef<HTMLDivElement, OptionItemProps>(
		(
			{
				item,
				index,
				playersTeams,
				optionsSelect,
				blockSelection,
				onChange,
			},
			ref
		) => {
			const { enrollOptions } = useEnrollContext();
			const toastTimeoutRef = useRef<NodeJS.Timeout | null>(null);
			const [open, setClose] = useState(false);
			const [statementReply, setStatementReply] = useState<string>('');
			const [numberReply, setNumberReply] = useState<number>(0);
			const [draftReply, setDraftReply] = useState<any>([]);
			const [draftReplySelect, setDraftReplySelect] = useState<any>([]);
			const positionsToFilter = item.draftPositions
				? item?.draftPositions.map(
						(position: any) => PlayersPositionDisplay[position]
				  )
				: [];
			const filteredPlayers = playersTeams
				.filter(
					(player: any) =>
						!draftReplySelect.includes(player.player.id)
				)
				.filter((player: any) =>
					positionsToFilter.includes(player.position)
				)
				.sort(
					(
						a: { player: { name: string } },
						b: { player: { name: any } }
					) => a.player.name.localeCompare(b.player.name)
				);
			const selectedPlayers = playersTeams
				.filter((player: any) =>
					draftReplySelect.includes(player.player.id)
				)
				.filter((player: any) =>
					positionsToFilter.includes(player.position)
				)
				.sort((a: any, b: any) =>
					a.player.name.localeCompare(b.player.name)
				);
			const isSelected = optionsSelect.some(
				(option: any) => option.optionId === item.id
			);

			useEffect(() => {
				const findExists = enrollOptions.find(
					(option: any) => option.optionId === item.id
				);

				if (findExists) {
					if (findExists.optionType === 0) {
						setStatementReply(
							findExists.statementReply ? 'Yes' : ''
						);
					}
					if (findExists.optionType === 1) {
						setDraftReply(findExists.draftReply);
						setDraftReplySelect(findExists.draftReply);
					}
					if (findExists.optionType === 2) {
						setNumberReply(findExists.numberReply);
					}
				}
			}, []);

			const handleChangeNumber = (data: number) => {
				if (blockSelection && !isSelected) {
					if (toastTimeoutRef.current) {
						clearTimeout(toastTimeoutRef.current);
					}

					toastTimeoutRef.current = setTimeout(() => {
						toast(
							<Toast variant="error">
								Desmarque uma pergunta opcional para selecionar
							</Toast>
						);
					}, 1000);
					return;
				}
				setNumberReply(data);
				onChange({
					optionId: item.id,
					optionType: item.optionType,
					sequence: item.sequence,
					numberReply,
				});
			};
			const handleChangeStatement = (data: string) => {
				if (blockSelection && !isSelected) {
					if (toastTimeoutRef.current) {
						clearTimeout(toastTimeoutRef.current);
					}

					toastTimeoutRef.current = setTimeout(() => {
						toast(
							<Toast variant="error">
								Desmarque uma pergunta opcional para selecionar
							</Toast>
						);
					}, 1000);
					return;
				}

				setStatementReply(data);
				onChange({
					optionId: item.id,
					statementReply: true,
					optionType: item.optionType,
					sequence: item.sequence,
				});
			};
			const handleChangeDraft = (data: any) => {
				const exists = draftReplySelect.includes(data);
				if (exists) {
					setDraftReplySelect(
						draftReplySelect.filter((item: any) => item !== data)
					);
				} else {
					if (
						draftReplySelect.length >= Number(item?.draftPlayersQtd)
					) {
						return toast(
							<Toast variant="error">
								Você já selecionou todos os jogadores.
							</Toast>
						);
					}
					setDraftReplySelect([...draftReplySelect, data]);
				}
			};
			const handleSaveDraft = () => {
				if (draftReplySelect.length < Number(item?.draftPlayersQtd)) {
					const countSelect =
						Number(item?.draftPlayersQtd) - draftReplySelect.length;

					return toast(
						<Toast variant="error">
							Selecione {countSelect}{' '}
							{countSelect > 1 ? 'jogadores' : 'jogador'}!
						</Toast>
					);
				}
				setDraftReply(draftReplySelect);
				onChange({
					optionId: item.id,
					optionType: item.optionType,
					sequence: item.sequence,
					draftReply: draftReplySelect,
				});
				setClose(!open);
			};

			const removeChangeEnroll = (id: string) => {
				if (item.optionType === 0) {
					setStatementReply('');
					onChange({
						optionId: item.id,
						delete: true,
					});
					return;
				}
				if (item.optionType === 1) {
					setDraftReply([]);
					setDraftReplySelect([]);
					onChange({
						optionId: item.id,
						delete: true,
					});
					return;
				}
				if (item.optionType === 2) {
					setNumberReply(0);
					onChange({
						optionId: item.id,
						delete: true,
					});
					return;
				}
			};

			const renderOption = (optionType: number) => {
				switch (optionType) {
					case 0:
						return (
							<div className="justify-end items-center gap-2 inline-flex">
								<div
									className={`flex gap-2 justify-start items-center px-4 py-2 rounded-[68px] transition-transform duration-300 ${
										statementReply === 'Yes'
											? 'bg-[#cccccc] scale-110'
											: 'scale-100'
									}`}
								>
									<div
										className="flex justify-start items-center gap-2"
										onClick={() =>
											handleChangeStatement('Yes')
										}
									>
										{statementReply === 'Yes' ? (
											<Checked />
										) : (
											<Unchecked />
										)}
										<div className="text-custom-black text-sm font-semibold font-bahnschrift">
											Sim
										</div>
									</div>
								</div>
							</div>
						);
					case 1:
						return (
							<div className="justify-between items-center gap-4 inline-flex">
								<div className="flex flex-col gap-0">
									{selectedPlayers.map(
										(player: any, index: number) => (
											<div
												key={index}
												className="p-4 justify-between items-center inline-flex"
											>
												<div className="h-10 justify-start items-center gap-4 flex">
													{player.player.image ? (
														<img
															className="w-10 h-10 rounded-[33.55px] object-cover"
															src={
																player.player
																	.image
															}
														/>
													) : (
														<div className="w-8 h-8 rounded-full text-white font-bahnschrift text-xs bg-primary items-center justify-center flex">
															{transformInitial(
																player.player
																	.name
															)}
														</div>
													)}

													<div className="flex-col justify-center items-start gap-1.5 inline-flex">
														<div className="justify-start items-center inline-flex">
															<div className="text-custom-black text-base font-normal font-bahnschrift leading-[14px]">
																{
																	player
																		.player
																		.name
																}
															</div>
														</div>
														<div className="justify-start items-center gap-1 inline-flex">
															<img
																className="w-4 h-4 object-scale-down"
																src={
																	player.team
																		.image
																}
															/>
															<div className="text-custom-black text-xs font-light font-bahnschrift leading-[14px]">
																{
																	player.team
																		.name
																}
															</div>
														</div>
													</div>
												</div>
											</div>
										)
									)}
								</div>
								<Button
									className="py-2 px-3"
									variant="black"
									textCustom="text-custom-white text-xs"
									onClick={() => {
										if (blockSelection && !isSelected) {
											if (toastTimeoutRef.current) {
												clearTimeout(
													toastTimeoutRef.current
												);
											}

											toastTimeoutRef.current =
												setTimeout(() => {
													toast(
														<Toast variant="error">
															Desmarque uma
															pergunta para
															selecionar!
														</Toast>
													);
												}, 1000);

											return;
										}
										setClose(!open);
									}}
								>
									Escalar
								</Button>
								<PlayersSelect
									id={item.id}
									open={open}
									onClose={() => setClose(!open)}
								>
									<div className="flex flex-col justify-between px-6 py-11 gap-4 max-h-[90vh]">
										{selectedPlayers.length > 0 && (
											<div className="flex flex-col gap-4 ">
												<div>
													<span className="text-[#ececec] text-lg font-normal font-bahnschrift">
														Jogadores escalados
													</span>
													<span className="text-[#6b6e74] text-xs font-light pl-1 font-bahnschrift">
														(
														{
															draftReplySelect.length
														}
														/{item.draftPlayersQtd})
													</span>
												</div>

												<div className="flex flex-col gap-4 overflow-auto max-h-[25vh]">
													{selectedPlayers.map(
														(
															player: any,
															index: number
														) => (
															<div
																key={index}
																className="h-[72px] p-4 bg-custom-gray10 rounded-[5px] shadow justify-between items-center inline-flex"
																onClick={() => {
																	handleChangeDraft(
																		player
																			.player
																			.id
																	);
																}}
															>
																<div className="h-10 justify-start items-center gap-4 flex">
																	{player
																		.player
																		.image ? (
																		<img
																			className="w-10 h-10 rounded-[33.55px] object-cover"
																			src={
																				player
																					.player
																					.image
																			}
																		/>
																	) : (
																		<div className="w-8 h-8 rounded-full text-white font-bahnschrift text-xs bg-primary items-center justify-center flex">
																			{transformInitial(
																				player
																					.player
																					.name
																			)}
																		</div>
																	)}

																	<div className="flex-col justify-center items-start gap-1.5 inline-flex">
																		<div className="justify-start items-center inline-flex">
																			<div className="text-custom-white text-base font-normal font-bahnschrift leading-[14px]">
																				{
																					player
																						.player
																						.name
																				}
																			</div>
																		</div>
																		<div className="justify-start items-center gap-1 inline-flex">
																			<img
																				className="w-4 h-4 object-scale-down"
																				src={
																					player
																						.team
																						.image
																				}
																			/>
																			<div className="text-custom-white text-xs font-light font-bahnschrift leading-[14px]">
																				{
																					player
																						.team
																						.name
																				}
																			</div>
																		</div>
																	</div>
																</div>

																{draftReplySelect.includes(
																	player
																		.player
																		.id
																) ? (
																	<Button
																		variant="delete"
																		textCustom="text-primary text-sm"
																	>
																		Dispensar
																	</Button>
																) : (
																	<Button className="py-1.5 px-3">
																		Selecionar
																	</Button>
																)}
															</div>
														)
													)}
												</div>
											</div>
										)}

										<div className="flex flex-col gap-4 h-fit">
											<div>
												<span className="text-[#ececec] text-lg font-normal font-bahnschrift">
													Monte sua equipe{' '}
												</span>
											</div>

											<div className="flex flex-col gap-4 overflow-auto max-h-[50vh]">
												{filteredPlayers.map(
													(
														player: any,
														index: number
													) => (
														<div
															key={index}
															className="h-[72px] p-4 bg-custom-gray10 rounded-[5px] shadow justify-between items-center inline-flex"
															onClick={() => {
																handleChangeDraft(
																	player
																		.player
																		.id
																);
															}}
														>
															<div className="h-10 justify-start items-center gap-4 flex">
																{player.player
																	.image ? (
																	<img
																		className="w-10 h-10 rounded-[33.55px] object-cover"
																		src={
																			player
																				.player
																				.image
																		}
																	/>
																) : (
																	<div className="w-8 h-8 rounded-full text-white font-bahnschrift text-xs bg-primary items-center justify-center flex">
																		{transformInitial(
																			player
																				.player
																				.name
																		)}
																	</div>
																)}

																<div className="flex-col justify-center items-start gap-1.5 inline-flex">
																	<div className="justify-start items-center inline-flex">
																		<div className="text-custom-white text-base font-normal font-bahnschrift leading-[14px]">
																			{
																				player
																					.player
																					.name
																			}
																		</div>
																	</div>
																	<div className="justify-start items-center gap-1 inline-flex">
																		<img
																			className="w-4 h-4 object-scale-down"
																			src={
																				player
																					.team
																					.image
																			}
																		/>
																		<div className="text-custom-white text-xs font-light font-bahnschrift leading-[14px]">
																			{
																				player
																					.team
																					.name
																			}
																		</div>
																	</div>
																</div>
															</div>

															{draftReplySelect.includes(
																player.player.id
															) ? (
																<Button
																	variant="delete"
																	textCustom="text-primary text-sm"
																>
																	Dispensar
																</Button>
															) : (
																<Button className="py-1.5 px-3">
																	Selecionar
																</Button>
															)}
														</div>
													)
												)}
											</div>
										</div>

										<div className="flex flex-row gap-4">
											<Button
												className="px-5 py-3 border border-custom-white"
												variant="black"
												textCustom="text-custom-white"
												onClick={() => {
													setClose(!open);
													setDraftReply([]);
												}}
											>
												Fechar
											</Button>
											<Button
												className="w-full py-3"
												textCustom="text-custom-white"
												onClick={handleSaveDraft}
											>
												Finalizar Escalação
											</Button>
										</div>
									</div>
								</PlayersSelect>
							</div>
						);
					case 2:
						return (
							<div className="flex items-center gap-2 w-full">
								<div className="w-full py-1.5">
									<div className="relative">
										<Slider.Root
											className="relative flex items-center select-none touch-none w-full h-5"
											value={[numberReply]}
											max={parseInt(item.numberMax)}
											min={parseInt(item.numberMin)}
											step={1}
											onValueChange={(value) =>
												handleChangeNumber(value[0])
											}
										>
											<Slider.Track className="bg-[#cccccc] relative flex-grow rounded-full h-0.5">
												<Slider.Range className="absolute bg-primary rounded-full h-full" />
											</Slider.Track>
											<Slider.Thumb
												aria-label="Volume"
												className="block w-2.5 h-2.5 bg-primary rounded-lg focus:outline-none"
											>
												<div className="absolute min-w-2.5 h-2.5 text-center -top-3.5 text-black text-[11px] font-normal font-bahnschrift">
													{numberReply}
												</div>
											</Slider.Thumb>
										</Slider.Root>
									</div>
								</div>
							</div>
						);
					default:
						return null;
				}
			};

			const selectedItem = () => {
				if (item.optionType === 0 && statementReply === 'Yes') {
					return true;
				}
				if (
					item.optionType === 1 &&
					draftReply.length === parseInt(item.draftPlayersQtd)
				) {
					return true;
				}
				if (item.optionType === 2 && numberReply !== 0) {
					return true;
				}
				return false;
			};

			return (
				<div
					ref={ref}
					tabIndex={-1}
					key={index}
					className="flex flex-col w-full lg:max-w-screen-xl lg:mx-auto"
				>
					<div
						className={
							'bg-custom-white1 rounded-lg ' +
							(selectedItem() ? 'border-2 border-primary' : '')
						}
					>
						<div
							className={
								'absolute w-6 h-6 bg-primary rounded-tl-[5px] rounded-br-[9px] justify-center items-center inline-flex cursor-pointer ' +
								(selectedItem() ? '' : 'hidden')
							}
							onClick={() => removeChangeEnroll(item.id)}
						>
							<RiCheckLine className="w-4 h-4 justify-center items-center flex text-custom-white1" />
						</div>
						<div
							className="flex flex-col gap-2 p-4"
							onClick={() => {
								if (item.optionType === 0) {
									handleChangeStatement('Yes');
								}
								if (item.optionType === 1) {
									if (blockSelection && !isSelected) {
										if (toastTimeoutRef.current) {
											clearTimeout(
												toastTimeoutRef.current
											);
										}

										toastTimeoutRef.current = setTimeout(
											() => {
												toast(
													<Toast variant="error">
														Desmarque uma pergunta
														opcional para selecionar
													</Toast>
												);
											},
											1000
										);
										return;
									}
									setClose(!open);
								}
							}}
						>
							<div
								className={
									'self-stretch justify-start items-center gap-2.5 inline-flex ' +
									(selectedItem() ? 'pl-4' : '')
								}
							>
								<div className="text-primary text-xs font-semibold font-bahnschrift">
									{item.winPoints} pontos
								</div>
							</div>
							<div className="self-stretch flex-col justify-start items-start gap-0.5 flex">
								<div className="self-stretch flex-col justify-start items-start gap-1 flex">
									<div className="self-stretch text-custom-black text-base font-semibold font-bahnschrift">
										{item.title}
									</div>
								</div>
							</div>

							{renderOption(item.optionType)}

							<div className="self-stretch justify-start items-center gap-2.5 inline-flex">
								<div className="text-[#9a9a9a] text-[10px] font-semibold font-bahnschrift">
									{item.mandatory
										? 'Obrigatória'
										: 'Opcional'}
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	)
);

export default OptionItem;
