import { create } from 'zustand';
import { UserDataProps, UserProps } from './types/user';
import { persist } from 'zustand/middleware';

type UserStore = {
	user: UserProps | null;
	saveUser: (user: UserProps) => void;
	clearUser: () => void;
};

interface UserProfileState {
	userData: UserDataProps | null;
	saveUserData: (data: UserDataProps) => void;
	updateUserData: (key: keyof UserDataProps, value: any) => void;
	clearUserData: () => void;
}

export const useUserStore = create(
	persist<UserStore>(
		(set) => ({
			user: null,
			saveUser: (user) => set({ user }),
			clearUser: () => set({ user: null }),
		}),
		{
			name: 'user',
			getStorage: () => localStorage,
		}
	)
);

export const useUserProfileStore = create(
	persist<UserProfileState>(
		(set) => ({
			userData: null,
			saveUserData: (userData) => set({ userData }),
			updateUserData: (key, value) =>
				set((state: any) => ({
					userData: {
						...state.userData,
						[key]: value,
					},
				})),
			clearUserData: () => set({ userData: null }),
		}),
		{
			name: 'userData',
			getStorage: () => localStorage,
		}
	)
);
