import Skeleton from 'react-loading-skeleton';
import { FeedData } from '../../types/types';
import FeedItem from '../FeedItem';

const FeedBody = ({ data, isLoading }: any) => {
	return (
		<div className="grid grid-cols-1 gap-3 lg:grid-cols-2">
			{isLoading ? (
				<Skeleton className="w-full h-[543px] rounded-lg" />
			) : (
				data &&
				data
					.sort(
						(a: any, b: any) =>
							parseInt(a.position) - parseInt(b.position)
					)
					.map((item: FeedData, index: number) => (
						<FeedItem item={item} key={index} />
					))
			)}
		</div>
	);
};

export default FeedBody;
