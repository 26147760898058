import { useContext, useState } from 'react';
import Button from '../../../../components/ui/button';
import { useApi } from '../../../../hooks/useApi';
import { useTokenStore } from '../../../../store/token';
import { toast } from 'sonner';
import Toast from '../../../../components/ui/toast';
import { SentryError } from '../../../../types/enums';
import { sentryCapture } from '../../../../helpers/utils';
import { AuthContext } from '../../../../contexts/Auth/AuthContext';
import { useBalanceStore } from '../../../../store/balance';

export const AccountClosure = () => {
	const api = useApi();
	const auth = useContext(AuthContext);
	const token = useTokenStore((state: any) => state.token);
	const [isLoading, setIsLoading] = useState(false);
	const currentBalance = useBalanceStore((state) => state.balance);

	const handleCancelAccount = async () => {
		if (parseFloat(currentBalance) > 0) {
			toast(
				<Toast variant="error">
					Antes de encerrar a sua conta solicite o saque do seu saldo!
				</Toast>
			);
			return;
		}
		setIsLoading(true);
		try {
			const response = await api.cancelAccount(token);
			if (!response?.success) {
				throw new Error(response.message);
			}

			toast(
				<Toast variant="success">Conta encerrada com sucesso!</Toast>
			);
			auth.signOut();
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'sendTokenValidation', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="flex flex-col gap-6">
			<p className="hidden lg:contents lg:text-4xl font-semibold text-left text-[#1d1f24] font-bahnschrift">
				Deseja encerrar a conta?
			</p>
			<p className="w-[342px] lg:w-[500px] text-base text-left text-custom-gray3 pb-52 lg:pb-2 font-bahnschrift">
				Tem certeza de que deseja encerrar sua conta? Confirmar essa
				ação resultará na perda permanente de seus dados.
			</p>

			<Button
				onClick={handleCancelAccount}
				isLoading={isLoading}
				className="px-3.5 py-3 font-bahnschrift text-center lg:w-96"
				textCustom="text-sm text-custom-white"
				variant="cancel"
			>
				Sim, quero encerrar minha conta
			</Button>
		</div>
	);
};
