import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface TokenState {
	token: string | null;
	saveToken: (token: string) => void;
	clearToken: () => void;
}

interface TokenGetStreamState {
	tokenGetStream: string | null;
	saveTokenGetStream: (token: string) => void;
	clearTokenGetStream: () => void;
}

export const useTokenStore = create(
	persist<TokenState>(
		(set) => ({
			token: null,
			saveToken: (token: string) => set({ token }),
			clearToken: () => set({ token: null }),
		}),
		{
			name: 'token',
			getStorage: () => localStorage,
		}
	)
);

export const useTokenGetStreamStore = create(
	persist<TokenGetStreamState>(
		(set) => ({
			tokenGetStream: null,
			saveTokenGetStream: (tokenGetStream: string) =>
				set({ tokenGetStream }),
			clearTokenGetStream: () => set({ tokenGetStream: null }),
		}),
		{
			name: 'tokenStream',
			getStorage: () => localStorage,
		}
	)
);
