export const Warning = () => {
	return (
		<svg
			width={66}
			height={66}
			viewBox="0 0 66 66"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="w-[66px] h-[66px] relative"
			preserveAspectRatio="xMidYMid meet"
		>
			<path
				d="M12.2928 57.7472H53.7078C57.9428 57.7472 60.5828 53.1547 58.4653 49.4972L37.7578 13.7197C35.6403 10.0622 30.3603 10.0622 28.2428 13.7197L7.53533 49.4972C5.41783 53.1547 8.05783 57.7472 12.2928 57.7472ZM33.0003 38.4972C31.4878 38.4972 30.2503 37.2597 30.2503 35.7472V30.2472C30.2503 28.7347 31.4878 27.4972 33.0003 27.4972C34.5128 27.4972 35.7503 28.7347 35.7503 30.2472V35.7472C35.7503 37.2597 34.5128 38.4972 33.0003 38.4972ZM35.7503 49.4972H30.2503V43.9972H35.7503V49.4972Z"
				fill="#DBBC19"
			/>
		</svg>
	);
};
