import { useContext, useEffect, useState } from 'react';
import { abbreviateAward, countRealTime } from '../../helpers/utils';
import { ChannelTypes, ClickActionType, ContentTypes } from '../../types/enums';
import { FeedData } from '../../types/types';
import { useModalLoginStore } from '../../store/modalLogin';
import { AuthContext } from '../../contexts/Auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import Button from '../ui/button';
import { useFeedUpdateStore } from '../../store/feedUpdate';
import { Clock } from '../Icons/Clock';
import { useModalDialogFeedStore } from '../../store/modalDialogFeed';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale/pt-BR';

interface IProps {
	item: FeedData;
	key: number;
}

const FeedItem = ({ item, key }: IProps) => {
	const navigate = useNavigate();
	const auth = useContext(AuthContext);
	const isNotLogged = !auth.user;

	const { modalDialogFeed, openModal } = useModalDialogFeedStore();
	const { openModalLogin } = useModalLoginStore();
	const { updateFeed } = useFeedUpdateStore();
	const [remainingTime, setRemainingTime] = useState(
		countRealTime(item.finishAt)
	);

	useEffect(() => {
		const interval = setInterval(() => {
			const time = countRealTime(item.finishAt);
			setRemainingTime(time);
			if (time.isFinished) {
				clearInterval(interval);
				updateFeed(true);
			}
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	const handleNavigator = () => {
		if (item.content.contentType === ContentTypes.TOURNAMENT) {
			if (isNotLogged) {
				openModalLogin(true, `/tournaments/${item.tournamentId}`);
				return;
			}
			navigate(`/tournaments/${item.tournamentId}`);
		} else {
			if (item.content.clickAction === ClickActionType.NOTHING) {
				return;
			}
			if (item.content.clickAction === ClickActionType.DIALOG) {
				openModal(!modalDialogFeed, item);
			}
			if (item.content.clickAction === ClickActionType.NAVIGATE) {
				window.open(item.content.urlAction, '_blank');
			}
		}
	};

	const renderFeed = (type: number) => {
		switch (type) {
			case 0:
				return (
					<button
						onClick={handleNavigator}
						className="relative w-full h-60 lg:h-80 overflow-hidden rounded-md"
						style={{
							boxShadow: '0px 4px 4px 0 rgba(0,0,0,0.25)',
						}}
					>
						{item.content.image && (
							<img
								className="absolute inset-0 lg:h-80 w-full h-full object-cover z-0"
								alt={item.content.title}
								src={item.content.image}
							/>
						)}

						<div
							className={`absolute inset-0 w-full h-full flex flex-col justify-between z-10 ${
								item.channel === ChannelTypes.HOMEHEAD &&
								'hidden'
							}`}
						>
							<div className="px-1 z-20 mx-auto p-1.5 rounded-br rounded-bl bg-custom-gray9/75 backdrop-blur-[7px]">
								<div className="flex flex-row gap-1 items-center justify-center">
									<Clock />
									<span className="text-base text-center text-custom-gray8 font-bahnschrift">
										Fecha em:
									</span>
									<span className="text-base text-center text-custom-white font-bahnschrift">
										{`${remainingTime.days}d : ` +
											`${remainingTime.hours
												.toString()
												.padStart(2, '0')}h : ` +
											`${remainingTime.minutes
												.toString()
												.padStart(2, '0')}m : ` +
											`${remainingTime.seconds
												.toString()
												.padStart(2, '0')}s`}
									</span>
								</div>
							</div>

							{item.content.image ? (
								<div
									className="rounded-[7px] bg-custom-black/[0.79] m-1 lg:m-0 lg:mx-6 lg:mb-3"
									style={{
										boxShadow:
											'0px 4px 4px 0 rgba(0,0,0,0.25)',
									}}
								>
									<div className="flex flex-col justify-between px-3.5 py-2.5 gap-1">
										<div className="flex flex-row items-center gap-2">
											<div className="font-bahnschrift text-custom-white text-left text-base w-2/3">
												{item.content.title}
											</div>

											<div className="bg-custom-black h-6 rounded-3xl px-5 py-0.5 w-full max-w-[150px] font-bahnschrift text-custom-white text-sm">
												{abbreviateAward(
													Number(item.award)
												)}{' '}
												em prêmio
											</div>
										</div>
										<div className="flex flex-row items-center gap-2">
											<div className="flex w-2/3 items-center">
												<div className="flex flex-row">
													{[1, 2, 3].map(
														(i, index) => (
															<div
																className={`${
																	i === 1 &&
																	'bg-primary'
																} ${
																	i === 2 &&
																	'bg-custom-gray6'
																} ${
																	i === 3 &&
																	'bg-custom-gray1'
																} w-6 h-6 rounded-full ${
																	i > 1 &&
																	'-ml-1.5'
																}`}
															/>
														)
													)}
												</div>
												<span className="font-bahnschrift text-custom-white text-sm pl-1.5">
													+40.000 entradas
												</span>
											</div>
											<Button className="w-full max-w-[150px] py-1.5">
												Participar
											</Button>
										</div>
									</div>
								</div>
							) : (
								<div
									dangerouslySetInnerHTML={{
										__html: item.content.html,
									}}
								/>
							)}
						</div>
					</button>
				);
			case 1:
				return (
					<button
						onClick={handleNavigator}
						className="relative w-full h-60 lg:h-80 overflow-hidden rounded-xl bg-custom-gray10"
					>
						{item.content.image ? (
							<img
								className={`absolute inset-0 w-full object-cover z-0 rounded-b-xl ${
									item.channel === ChannelTypes.HOMEHEAD
										? 'h-60 lg:h-80'
										: 'h-[200px] lg:h-[280px]'
								}`}
								alt={item.content.title}
								src={item.content.image}
							/>
						) : (
							<div
								className={`absolute inset-0 w-full object-cover z-0 rounded-b-xl ${
									item.channel === ChannelTypes.HOMEHEAD
										? 'h-60 lg:h-80'
										: 'h-[200px] lg:h-[280px]'
								}`}
								dangerouslySetInnerHTML={{
									__html: item.content.html,
								}}
							/>
						)}

						<div
							className={`absolute inset-0 w-full h-full flex flex-col justify-between z-10 ${
								item.channel === ChannelTypes.HOMEHEAD &&
								'hidden'
							}`}
						>
							<div className="bg-[#262626] w-[120px] rounded-full p-2.5 m-3.5 font-bahnschrift text-custom-white text-sm">
								Publicidade
							</div>

							<div className="font-bahnschrift text-custom-white text-sm mb-3">
								Saiba mais
							</div>
						</div>
					</button>
				);
			case 2:
				return (
					<button
						onClick={handleNavigator}
						className="relative w-full h-60 lg:h-80 overflow-hidden rounded-xl bg-custom-gray10"
					>
						{item.content.image ? (
							<img
								className={`absolute inset-0 w-full object-cover z-0 rounded-b-xl ${
									item.channel === ChannelTypes.HOMEHEAD
										? 'h-60 lg:h-80'
										: 'h-[200px] lg:h-[280px]'
								}`}
								alt={item.content.title}
								src={item.content.image}
							/>
						) : (
							<div
								className={`absolute inset-0 w-full object-cover z-0 rounded-b-xl ${
									item.channel === ChannelTypes.HOMEHEAD
										? 'h-60 lg:h-80'
										: 'h-[200px] lg:h-[280px]'
								}`}
								dangerouslySetInnerHTML={{
									__html: item.content.html,
								}}
							/>
						)}

						<div
							className={`absolute inset-0 w-full h-full flex flex-col justify-end z-10 ${
								item.channel === ChannelTypes.HOMEHEAD &&
								'hidden'
							}`}
						>
							<div
								className="px-4"
								style={{
									background:
										'linear-gradient(0.06deg, #1d1f24 0.05%, rgba(61,61,61,0.71) 170.43%)',
								}}
							>
								<div className="bg-[#262626] w-[180px] m rounded-full p-2.5 -mt-[18px] font-bahnschrift text-custom-white text-sm">
									{format(
										item.content.created_at,
										'dd/MM/yyyy - HH:mm',
										{
											locale: ptBR,
										}
									)}
								</div>
								<div className="font-bahnschrift text-custom-white text-sm text-left pt-2 pb-5">
									{item.content.title}
								</div>
							</div>
						</div>
					</button>
				);
			default:
				return <></>;
		}
	};

	return (
		<div
			key={key}
			className="w-full rounded-md px-3 gap-2 flex flex-col items-start lg:max-w-screen-xl "
		>
			{renderFeed(item.content.contentType)}
		</div>
	);
};

export default FeedItem;
