import { RiEyeFill, RiEyeOffFill } from '@remixicon/react';
import { useBalanceStore } from '../../store/balance';

const HideBalance = () => {
	const { showAndHideBalance } = useBalanceStore();
	const hideBalance = useBalanceStore((state) => state.hideBalance);

	return (
		<button
			type="button"
			onClick={() => {
				showAndHideBalance(!hideBalance);
			}}
		>
			{hideBalance ? (
				<RiEyeFill size={20} color="#FFFFFF" className="ml-2" />
			) : (
				<RiEyeOffFill size={20} color="#FFFFFF" className="ml-2" />
			)}
		</button>
	);
};

export default HideBalance;
