import { z } from 'zod';
import { validateUserName } from '../helpers/utils';

export const LoginSchema = z.object({
	password: z
		.string()
		.min(6, 'A senha deve conter entre 6 a 20 caracteres')
		.max(20, 'A senha deve conter entre 6 a 20 caracteres')

		.refine((val) => val.length > 8, {
			message: 'A senha é inválida!',
		})
		.refine((val) => /[A-Z]/.test(val), {
			message: 'A senha é inválida!',
		})
		.refine((val) => /[a-z]/.test(val), {
			message: 'A senha é inválida!',
		})
		.refine((val) => /\d/.test(val), {
			message: 'A senha é inválida!',
		})
		.refine((val) => /[^a-zA-Z0-9]/.test(val), {
			message: 'A senha é inválida!',
		})
		.optional(),
	email: z
		.string()
		.email({ message: 'Informe um e-mail válido' })
		.optional()
		.or(z.literal('')),
	nick: z
		.string()
		.min(4, 'Nick deve conter entre 4 a 20 caracteres')
		.max(20, 'A senha deve conter entre 4 a 20 caracteres')
		.nullable()
		.optional()
		.or(z.literal('')),
});

export const RegisterSchema = z
	.object({
		email: z
			.string()
			.min(1, 'Preencha o campo')
			.email('Informe um e-mail válido'),
		nick: z
			.string()
			.min(1, 'Preencha o campo')
			.refine((val) => !val || validateUserName(val), {
				message: 'Apelido inválido!',
			}),
		password: z
			.string()
			.min(1, 'Preencha o campo')
			.refine((val) => val.length > 8, {
				message: 'A senha deve ter pelo menos 8 caracteres!',
			})
			.refine((val) => /[A-Z]/.test(val), {
				message: 'A senha deve ter pelo menos uma letra maiúscula!',
			})
			.refine((val) => /[a-z]/.test(val), {
				message: 'A senha deve ter pelo menos uma letra minúscula!',
			})
			.refine((val) => /\d/.test(val), {
				message: 'A senha deve ter pelo menos um dígito!',
			})
			.refine((val) => /[^a-zA-Z0-9]/.test(val), {
				message: 'A senha deve ter pelo menos um caractere especial!',
			}),
		confirmPassword: z.string().min(1, 'Preencha o campo'),
		termsOfUse: z.boolean().refine((val) => val === true, {
			message: 'Por favor leia e aceite os termos e condições',
		}),
		termsPrivacyPolicy: z.boolean().refine((val) => val === true, {
			message: 'Por favor leia e aceite os termos e condições',
		}),
		termsOfLegalAge: z.boolean().refine((val) => val === true, {
			message: 'Por favor leia e aceite os termos e condições',
		}),
	})
	.refine((data) => data.password === data.confirmPassword, {
		message: 'As senhas não coincidem',
		path: ['confirmPassword'],
	});

export const ForgotPassSchema = z
	.object({
		email: z
			.string()
			.min(1, 'Preencha o campo')
			.email('Informe um e-mail válido'),
		password: z
			.string()
			.min(1, 'Preencha o campo')
			.refine((val) => val.length > 8, {
				message: 'A senha deve ter pelo menos 8 caracteres!',
			})
			.refine((val) => /[A-Z]/.test(val), {
				message: 'A senha deve ter pelo menos uma letra maiúscula!',
			})
			.refine((val) => /[a-z]/.test(val), {
				message: 'A senha deve ter pelo menos uma letra minúscula!',
			})
			.refine((val) => /\d/.test(val), {
				message: 'A senha deve ter pelo menos um dígito!',
			})
			.refine((val) => /[^a-zA-Z0-9]/.test(val), {
				message: 'A senha deve ter pelo menos um caractere especial!',
			}),
		confirmPassword: z.string().min(1, 'Preencha o campo'),
	})
	.refine((data) => data.password === data.confirmPassword, {
		message: 'As senhas não coincidem',
		path: ['confirmPassword'],
	});
