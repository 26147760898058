export const Rss = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={`flex-grow-0 flex-shrink-0 w-5 h-5 relative text-custom-white`}
		>
			<g id="rss_feed" clip-path="url(#clip0_1157_1993)">
				<path
					id="Vector"
					d="M5.14967 16.6685C6.15299 16.6685 6.96634 15.8551 6.96634 14.8518C6.96634 13.8485 6.15299 13.0352 5.14967 13.0352C4.14636 13.0352 3.33301 13.8485 3.33301 14.8518C3.33301 15.8551 4.14636 16.6685 5.14967 16.6685Z"
					fill="white"
				/>
				<path
					id="Vector_2"
					d="M4.65801 8.52682C3.95801 8.41015 3.33301 8.98515 3.33301 9.69349C3.33301 10.2852 3.77467 10.7602 4.35801 10.8602C6.79134 11.2852 8.70801 13.2102 9.14134 15.6435C9.24134 16.2268 9.71634 16.6685 10.308 16.6685C11.0163 16.6685 11.5913 16.0435 11.483 15.3435C10.9163 11.8435 8.15801 9.08515 4.65801 8.52682ZM4.63301 3.76849C3.94134 3.69349 3.33301 4.25182 3.33301 4.94349C3.33301 5.55182 3.79134 6.05182 4.39134 6.11015C9.39967 6.61015 13.383 10.5935 13.883 15.6018C13.9413 16.2102 14.4413 16.6685 15.0497 16.6685C15.7497 16.6685 16.2997 16.0602 16.233 15.3685C15.6247 9.25182 10.758 4.37682 4.63301 3.76849Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1157_1993">
					<rect width="20" height="20" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
