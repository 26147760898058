export const Notification = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={`flex-grow-0 flex-shrink-0 w-5 h-5 relative text-custom-white`}
		>
			<g id="notifications_none" clip-path="url(#clip0_1157_2004)">
				<path
					id="Vector"
					d="M16.0748 14.407L14.9998 13.332V9.16536C14.9998 6.60703 13.6331 4.46536 11.2498 3.8987V3.33203C11.2498 2.64036 10.6915 2.08203 9.9998 2.08203C9.30813 2.08203 8.7498 2.64036 8.7498 3.33203V3.8987C6.35813 4.46536 4.9998 6.5987 4.9998 9.16536V13.332L3.9248 14.407C3.3998 14.932 3.76647 15.832 4.50813 15.832H15.4831C16.2331 15.832 16.5998 14.932 16.0748 14.407ZM13.3331 14.1654H6.66647V9.16536C6.66647 7.0987 7.9248 5.41536 9.9998 5.41536C12.0748 5.41536 13.3331 7.0987 13.3331 9.16536V14.1654ZM9.9998 18.332C10.9165 18.332 11.6665 17.582 11.6665 16.6654H8.33313C8.33313 17.582 9.0748 18.332 9.9998 18.332Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1157_2004">
					<rect width="20" height="20" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
