import { RiCloseFill } from '@remixicon/react';

type ModalProps = {
	id: string;
	open: boolean;
	onClose: () => void;
	children: React.ReactNode;
};

const ModalEnroll = ({ open, onClose, children, id }: ModalProps) => {
	return (
		<div
			id={id}
			className={`fixed inset-0 flex justify-center items-center transition-colors z-50 ${
				open ? 'visible bg-custom-gray1/70' : 'invisible'
			}`}
		>
			<div
				onClick={(e) => e.stopPropagation()}
				className={`flex flex-col w-full lg:max-w-96 lg:mx-auto mx-4 bg-custom-white rounded-xl shadow p-6 transition-all ${
					open ? 'scale-100 opacity-100' : 'scale-125 opacity-0'
				}`}
			>
				<button className="flex flex-grow-0 justify-end cursor-default">
					<RiCloseFill onClick={onClose} className="cursor-pointer" />
				</button>
				{children}
			</div>
		</div>
	);
};

export default ModalEnroll;
