export const Profile = () => {
	return (
		<svg
			width="21"
			height="20"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={`flex-grow-0 flex-shrink-0 w-5 h-5 relative text-custom-white`}
		>
			<g id="menu" clip-path="url(#clip0_1157_2009)">
				<path
					id="Vector"
					d="M3.83333 15H17.1667C17.625 15 18 14.625 18 14.1667C18 13.7083 17.625 13.3333 17.1667 13.3333H3.83333C3.375 13.3333 3 13.7083 3 14.1667C3 14.625 3.375 15 3.83333 15ZM3.83333 10.8333H17.1667C17.625 10.8333 18 10.4583 18 10C18 9.54167 17.625 9.16667 17.1667 9.16667H3.83333C3.375 9.16667 3 9.54167 3 10C3 10.4583 3.375 10.8333 3.83333 10.8333ZM3 5.83333C3 6.29167 3.375 6.66667 3.83333 6.66667H17.1667C17.625 6.66667 18 6.29167 18 5.83333C18 5.375 17.625 5 17.1667 5H3.83333C3.375 5 3 5.375 3 5.83333Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1157_2009">
					<rect
						width="20"
						height="20"
						fill="white"
						transform="translate(0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
