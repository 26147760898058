import { RiAddFill, RiRestartLine, RiSubtractLine } from '@remixicon/react';
import Footer from '../../../../components/Footer';
import { Layout } from '../../../../components/layout';
import { useUserProfileStore } from '../../../../store/user';
import { useEffect, useState } from 'react';
import { SendDocument } from '../../MyProfile/ProfileInfo/Edit/Document';
import { Pix } from '../../../../components/Icons/Pix';
import { CurrencyInput } from 'react-currency-mask';
import { toast } from 'sonner';
import Toast from '../../../../components/ui/toast';
import { sentryCapture } from '../../../../helpers/utils';
import { SentryError } from '../../../../types/enums';
import { useApi } from '../../../../hooks/useApi';
import { useTokenStore } from '../../../../store/token';
import { PixInfoDetails } from '../../../../components/PixInfoDetails';
import { useModalBalanceAlertStore } from '../../../../store/modalBalanceAlert';

export const Deposit = () => {
	const api = useApi();
	const { depositSuggestion } = useModalBalanceAlertStore();
	const token = useTokenStore((state: any) => state.token);
	const userData = useUserProfileStore((state: any) => state.userData);
	const [deposit, setDeposit] = useState<any>(0.0);
	const [isLoading, setIsLoading] = useState(false);

	const [pixSuccess, setPixSuccess] = useState(false);
	const [pixDetails, setPixDetails] = useState<any>(null);
	const limitDeposit: any = 5000.0;
	const depositFixValue = [1, 2, 5, 10, 20, 30, 50, 100, 200];
	const paymentMethods = [
		{
			method: 'pix',
			title: 'Depositar usando Pix',
			icon: () => <Pix />,
		},
	];

	useEffect(() => {
		if (depositSuggestion > 0) {
			setDeposit(depositSuggestion);
		}
	}, []);

	const subtractDeposit = () => {
		if (deposit <= 0) {
			return;
		}
		setDeposit(deposit - 1);
	};
	const addDeposit = () => {
		setDeposit(deposit + 1);
	};

	const handleDeposit = async (method: any) => {
		if (deposit <= 0) {
			toast(
				<Toast variant="error">
					Selecione ou informe valor de depósito.
				</Toast>
			);
			return;
		}

		const body = {
			provisionAmt: deposit,
		};

		setIsLoading(true);
		try {
			const response = await api.createNewDeposit(method, body, token);
			if (!response?.success) {
				throw new Error(response.message);
			}
			const { copyPaste, qrCode, txid } = response;
			setPixSuccess(true);
			setPixDetails({ copyPaste, qrCode, deposit, txid });
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'handleDeposit', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	const callBackPixPayment = () => {
		setDeposit(0.0);
		setPixDetails(null);
		setPixSuccess(false);
	};

	return (
		<Layout>
			<div className="flex flex-col py-6 px-3.5 lg:max-w-screen-xl lg:mx-auto lg:pt-4">
				{pixSuccess ? (
					<PixInfoDetails
						data={pixDetails}
						onClosePayment={callBackPixPayment}
					/>
				) : (
					<>
						<p className="text-xl text-center font-semibold text-custom-gray1 pb-5 font-bahnschrift">
							Depositar
						</p>
						{!userData.documentNumber && (
							<SendDocument
								isDocumentFalse={userData?.documentNumber}
							/>
						)}

						<div className="flex flex-col gap-3 px-5 pt-6">
							<div className="">
								<p className="text-base text-left text-custom-gray1 pb-1.5 font-bahnschrift">
									Informe o valor a depositar
								</p>
								<p className="text-sm text-left text-custom-gray3 font-bahnschrift">
									Novos depósitos estão limitados a R$
									5.000,00
								</p>
							</div>

							<div className="grid grid-cols-3 gap-3 items-center justify-center">
								{depositFixValue.map((value, index) => (
									<button
										disabled={isLoading}
										onClick={() => setDeposit(value)}
										className={
											'flex justify-center items-center  gap-2.5 px-[29px] py-3 rounded-lg border border-primary ' +
											(value === deposit &&
												'bg-custom-gray')
										}
										key={index}
									>
										<p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary">
											R$ {value.toFixed(2)}
										</p>
									</button>
								))}
							</div>

							<div className="py-3">
								<div className="flex flex-row items-center justify-between rounded-lg border border-r-custom-gray2 py-4 px-1.5 ">
									<button
										disabled={isLoading}
										onClick={subtractDeposit}
										className="cursor-pointer h-7 w-7 rounded bg-primary items-center justify-center flex mx-2"
									>
										<RiSubtractLine
											size={16}
											className="text-custom-white"
										/>
									</button>

									<div className="relative w-3/4">
										<label className="absolute -top-1.5 left-2.5 text-[10px] text-center text-custom-gray3 font-bahnschrift focus:border-0 active:border-0">
											R$
										</label>
										<CurrencyInput
											value={deposit}
											onChangeValue={(
												_,
												originalValue
											) => {
												if (
													limitDeposit < originalValue
												) {
													toast(
														<Toast variant="error">
															Seu limite de
															depósito é R${' '}
															{limitDeposit.toFixed(
																2
															)}
														</Toast>
													);
												}
												setDeposit(originalValue);
											}}
											hideSymbol
											InputElement={
												<input
													disabled={isLoading}
													className="w-full text-xl font-bold text-center text-custom-gray2 font-bahnschrift focus:border-0 active:border-0 focus-visible:border-0 outline-none"
												/>
											}
										/>
									</div>

									<button
										disabled={isLoading}
										onClick={addDeposit}
										className="cursor-pointer h-7 w-7 rounded bg-primary items-center justify-center flex mx-2"
									>
										<RiAddFill
											size={24}
											className="text-custom-white"
										/>
									</button>
								</div>
							</div>

							<div className="flex flex-col gap-4">
								<p className="text-base text-left text-custom-gray1 pb-1.5 font-bahnschrift">
									Escolha a forma de pagamento
								</p>

								<div className="flex flex-col gap-4">
									{isLoading ? (
										<div className="flex items-center justify-center">
											<RiRestartLine className="mr-2 h-4 w-4 animate-spin" />
										</div>
									) : (
										paymentMethods.map(
											(methods: any, index) => (
												<button
													onClick={() =>
														handleDeposit(
															methods?.method
														)
													}
													disabled={isLoading}
													className="flex flex-row justify-between rounded-lg bg-custom-gray7 border border-custom-gray4 px-6 py-3"
													key={index}
												>
													<p className="text-sm text-left text-custom-gray1 font-bahnschrift">
														{methods?.title}
													</p>
													{methods?.icon &&
														methods.icon()}
												</button>
											)
										)
									)}
								</div>
							</div>
						</div>
					</>
				)}
			</div>

			<Footer />
		</Layout>
	);
};
