import { createContext } from 'react';
import { UserProps } from '../../store/types/user';

export type AuthContextType = {
	user: UserProps | null;
	signInToken: (email: string, code: string) => Promise<any>;
	signIn: (login: string, password: string) => Promise<any>;
	register: (data: any) => Promise<any>;
	forgotPass: (data: any) => Promise<any>;
	signOut: () => void;
};

export const AuthContext = createContext<AuthContextType>(null!);
