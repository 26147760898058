import { RiAddFill, RiSubtractLine } from '@remixicon/react';
import { useState } from 'react';

const AccordionItem = ({ title, content, isOpen, onClick }: any) => {
	const itemActive = isOpen
		? 'rounded-lg bg-[#131416] border-t-0 border-r-0 border-b border-l-0 border-[#131416]'
		: 'border-b border-custom-gray2';

	return (
		<div className={`py-4 px-3.5 gap-4 flex flex-col ${itemActive}`}>
			<button
				className="flex items-center self-stretch flex-grow-0 flex-shrink-0 focus:outline-none gap-4"
				onClick={onClick}
			>
				{isOpen ? (
					<RiAddFill size={16} className="text-custom-white" />
				) : (
					<RiSubtractLine size={16} className="text-custom-white" />
				)}

				<p className="text-xs font-semibold text-left text-custom-white font-bahnschrift">
					{title}
				</p>
			</button>
			{isOpen && (
				<div className="text-custom-white text-left text-xs font-bahnschrift">
					<p>{content}</p>
				</div>
			)}
		</div>
	);
};

const Accordion = ({ items }: any) => {
	const [openIndex, setOpenIndex] = useState(null);

	const handleClick = (index: any) => {
		setOpenIndex(openIndex === index ? null : index);
	};

	return (
		<div className="w-full flex flex-col overflow-hidden gap-4">
			{items.map((item: any, index: number) => (
				<AccordionItem
					key={index}
					title={item.title}
					content={item.content}
					isOpen={openIndex === index}
					onClick={() => handleClick(index)}
				/>
			))}
		</div>
	);
};

export default Accordion;
