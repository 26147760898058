export const Pix = () => {
	return (
		<svg
			width={22}
			height={23}
			viewBox="0 0 22 23"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="w-[22px] h-[22px]"
			preserveAspectRatio="none"
		>
			<path
				d="M4.95035 5.49875H4.61035L8.63035 1.47875C9.94035 0.17375 12.0604 0.17375 13.3704 1.47875L17.3904 5.49875H17.0504C16.2503 5.49875 15.4954 5.80875 14.9304 6.37875L11.5304 9.76375C11.2354 10.0588 10.7654 10.0588 10.4704 9.76375L7.07035 6.37875C6.50535 5.80875 5.75035 5.49875 4.95035 5.49875Z"
				fill="#4DB6AC"
			/>
			<path
				d="M17.0504 17.5019H17.3904L13.3704 21.5219C12.0604 22.8269 9.94035 22.8269 8.63035 21.5219L4.61035 17.5019H4.95035C5.75035 17.5019 6.50535 17.1919 7.07035 16.6219L10.4704 13.2369C10.7654 12.9419 11.2354 12.9419 11.5304 13.2369L14.9304 16.6219C15.4954 17.1919 16.2503 17.5019 17.0504 17.5019Z"
				fill="#4DB6AC"
			/>
			<path
				d="M21.0197 13.87L18.3897 16.5H17.0497C16.5147 16.5 16.0147 16.29 15.6347 15.915L12.2347 12.525C11.5547 11.845 10.4447 11.845 9.76473 12.525L6.36473 15.915C5.98473 16.29 5.48473 16.5 4.94973 16.5H3.60973L0.979727 13.87C-0.325273 12.56 -0.325273 10.44 0.979727 9.13L3.60973 6.5H4.94973C5.48473 6.5 5.98473 6.71 6.36473 7.085L9.76473 10.475C10.1047 10.815 10.5547 10.985 10.9997 10.985C11.4447 10.985 11.8947 10.815 12.2347 10.475L15.6347 7.085C16.0147 6.71 16.5147 6.5 17.0497 6.5H18.3897L21.0197 9.13C22.3247 10.44 22.3247 12.56 21.0197 13.87Z"
				fill="#4DB6AC"
			/>
		</svg>
	);
};
