import { icons } from 'lucide-react';

type IconProps = {
	name: string;
	classname?: string;
};

const Icon = ({ name, classname }: IconProps) => {
	const LucideIcon = icons[name as keyof typeof icons];

	return <LucideIcon className={classname} />;
};

export default Icon;
