interface AlertProps {
	textInfo: string;
	onClick: () => void;
}

export const Alert = ({ textInfo, onClick }: AlertProps) => {
	return (
		<div className="h-[74px] bg-primary mb-1.5 flex justify-between items-center px-3.5 lg:bg-custom-yellow lg:rounded-lg lg:bg-primary/10 lg:border-2 lg:border-primary">
			<p className="text-base text-left text-custom-white font-bahnschrift lg:text-custom-black">
				{textInfo}
			</p>

			<div className="flex justify-center items-center gap-2.5 px-3.5 py-1.5 rounded-lg border border-custom-white lg:border-custom-black">
				<button
					onClick={onClick}
					className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center font-bahnschrift text-custom-white lg:text-custom-black"
				>
					Clique aqui
				</button>
			</div>
		</div>
	);
};
