import { create } from 'zustand';

type ModalDialogFeedStore = {
	modalDialogFeed: boolean;
	data: any;
	openModal: (modalLogin: boolean, data: any) => void;
	closeModal: () => void;
};

export const useModalDialogFeedStore = create<ModalDialogFeedStore>((set) => ({
	modalDialogFeed: false,
	data: null,
	openModal: (modalDialogFeed, data) => set({ modalDialogFeed, data }),
	closeModal: () => set({ modalDialogFeed: false, data: null }),
}));
