import { Ri24HoursFill, RiCloseFill } from '@remixicon/react';

type ModalProps = {
	id: string;
	open: boolean;
	onClose: () => void;
	children: React.ReactNode;
};

const ModalDialog = ({ open, onClose, children, id }: ModalProps) => {
	return (
		<div
			id={id}
			onClick={onClose}
			className={`fixed inset-0 flex justify-center items-center transition-colors z-50 ${
				open ? 'visible bg-custom-gray1/70' : 'invisible'
			}`}
		>
			<div
				onClick={(e) => e.stopPropagation()}
				className={`w-full max-h-screen lg:max-w-[800px] lg:mx-auto mx-4 bg-custom-white rounded-xl shadow transition-all ${
					open ? 'scale-100 opacity-100' : 'scale-125 opacity-0'
				}`}
			>
				<div className="flex flex-col gap-3">
					<button
						onClick={onClose}
						className="rounded-lg text-gray-400 px-6 pt-6 pb-4 bg-white hover:bg-gray-50 hover:text-gray-600 flex justify-end"
					>
						<RiCloseFill />
					</button>
					{children}
				</div>
			</div>
		</div>
	);
};

export default ModalDialog;
