import { RiCheckFill } from '@remixicon/react';

type IProps = {
	value: string;
};

const PasswordCaption = ({ value }: IProps) => {
	return (
		<div className="flex flex-col gap-1.5">
			<div className="flex flex-row gap-1.5">
				<div
					className={`w-4 h-4 rounded-[47px] flex items-center justify-center ${
						value.length > 7 ? 'bg-primary' : 'border border-black'
					}`}
				>
					{value.length > 7 && (
						<RiCheckFill className="w-3 text-custom-white" />
					)}
				</div>

				<p className="text-xs text-left text-custom-black font-bahnschrift">
					Pelo menos 8 caracteres
				</p>
			</div>
			<div className="flex flex-row gap-1.5">
				<div
					className={`w-4 h-4 rounded-[47px] flex items-center justify-center ${
						/[^a-zA-Z0-9]/.test(value)
							? 'bg-primary'
							: 'border border-black'
					}`}
				>
					{/[^a-zA-Z0-9]/.test(value) && (
						<RiCheckFill className="w-3 text-custom-white" />
					)}
				</div>
				<p className="text-xs text-left text-custom-black font-bahnschrift">
					Pelo menos 1 caractere especial (@#!*%:;)
				</p>
			</div>
			<div className="flex flex-row gap-1.5">
				<div
					className={`w-4 h-4 rounded-[47px] flex items-center justify-center ${
						/[A-Z]/.test(value)
							? 'bg-primary'
							: 'border border-black'
					}`}
				>
					{/[A-Z]/.test(value) && (
						<RiCheckFill className="w-3 text-custom-white" />
					)}
				</div>
				<p className="text-xs text-left text-custom-black font-bahnschrift">
					Pelo menos 1 letra maiúscula
				</p>
			</div>
			<div className="flex flex-row gap-1.5">
				<div
					className={`w-4 h-4 rounded-[47px] flex items-center justify-center ${
						/[a-z]/.test(value)
							? 'bg-primary'
							: 'border border-black'
					}`}
				>
					{/[a-z]/.test(value) && (
						<RiCheckFill className="w-3 text-custom-white" />
					)}
				</div>
				<p className="text-xs text-left text-custom-black font-bahnschrift">
					Pelo menos 1 letra minúscula
				</p>
			</div>
			<div className="flex flex-row gap-1.5">
				<div
					className={`w-4 h-4 rounded-[47px] flex items-center justify-center ${
						/\d/.test(value) ? 'bg-primary' : 'border border-black'
					}`}
				>
					{/\d/.test(value) && (
						<RiCheckFill className="w-3 text-custom-white" />
					)}
				</div>
				<p className="text-xs text-left text-custom-black font-bahnschrift">
					Pelo menos 1 número
				</p>
			</div>
		</div>
	);
};

export default PasswordCaption;
