import { RiArrowLeftSLine } from '@remixicon/react';
import { Layout } from '../../../components/layout';
import { Tabs } from '../../../components/Tabs';
import { ProfileInfo } from './ProfileInfo';
import { DocumentsInfo } from './Documents';
import { AccountClosure } from './AccountClosure';
import Footer from '../../../components/Footer';

export const MyProfile = () => {
	const tabsData = [
		{ title: 'Registro', renderPage: () => <ProfileInfo /> },
		{ title: 'Documentos', renderPage: () => <DocumentsInfo /> },
		{
			title: 'Encerramento de Conta',
			renderPage: () => <AccountClosure />,
		},
	];

	return (
		<Layout>
			<div className="items-center flex-row flex justify-between py-6 px-3.5 lg:max-w-screen-xl lg:mx-auto lg:pt-4">
				<a href="/profile">
					<RiArrowLeftSLine />
				</a>
				<p className="text-xl font-semibold text-custom-gray1 font-bahnschrift">
					Meu Perfil
				</p>
				<div></div>
			</div>

			<Tabs initialPage="Registro" render={tabsData} />
			<Footer />
		</Layout>
	);
};
