import React, {
	createContext,
	useState,
	useContext,
	useEffect,
	ReactNode,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useUserStore } from '../../store/user';
import { useApi } from '../../hooks/useApi';
import { useTokenStore } from '../../store/token';
import Modal from '../../components/Modal/modal';
import { Warning } from '../../components/Icons/Warning';
import Button from '../../components/ui/button';

interface PromoContextType {
	promocode: string | null;
}

const PromoContext = createContext<PromoContextType | undefined>(undefined);

interface PromoProviderProps {
	children: ReactNode;
}

export const PromoProvider: React.FC<PromoProviderProps> = ({ children }) => {
	const api = useApi();
	const token = useTokenStore((state: any) => state.token);
	const [promocode, setPromocode] = useState<string | null>(null);
	const [changedPromocodeModal, setChangedPromocodeMoldal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [oldAffiliate, setOldAffiliate] = useState('');
	const [newAffiliate, setNewAffiliate] = useState('');
	const location = useLocation();
	const user = useUserStore((state) => state.user);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const code = queryParams.get('pc');
		setPromocode(code);

		if (code && user) {
			validityPromocode(code);
		}
	}, [location.search, user]);

	const validityPromocode = async (promocode: any) => {
		try {
			const response = await api.validityPromocode(
				{
					promocode,
				},
				token
			);
			if (!response?.success) {
				throw new Error(response.message);
			}

			if (response.changedAfiliateMessage) {
				setChangedPromocodeMoldal(true);
				setNewAffiliate(response.newAffiliate);
				setOldAffiliate(response.oldAffiliate);
			}
		} catch (error) {
			console.error('error Promocode:', error);
		}
	};

	const confirmChangedPromocode = async (promocode: any) => {
		setIsLoading(true);
		try {
			const response = await api.confirmChangedPromocode(
				{
					promocode,
				},
				token
			);
			if (!response?.success) {
				throw new Error(response.message);
			}
			setChangedPromocodeMoldal(false);
		} catch (error) {
			console.error('error Promocode:', error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<PromoContext.Provider value={{ promocode }}>
			{children}

			<Modal
				id="changedPromocode"
				open={changedPromocodeModal}
				onClose={() =>
					setChangedPromocodeMoldal(!changedPromocodeModal)
				}
			>
				<div className="flex flex-col gap-6 items-center">
					<Warning />

					<p className="text-2xl font-bold text-center w-96 text-custom-gray2 font-bahnschrift">
						Atenção
					</p>
					<p className="text-sm text-center text-custom-gray2 font-bahnschrift">
						Identificamos que você atualmente está associado a conta
						de {oldAffiliate}
					</p>

					<p className="text-sm text-center text-custom-gray2 font-bahnschrift">
						Você acabou de acessar a plataforma utilizando um
						promocode fornecido por {newAffiliate}
					</p>

					<div className="w-full pt-2 flex flex-row gap-2">
						<Button
							onClick={() => {
								setChangedPromocodeMoldal(
									!changedPromocodeModal
								);
							}}
							variant="destructive"
							className={'w-full p-3'}
							isLoading={isLoading}
						>
							Quero manter minha conta associada a {oldAffiliate}
						</Button>
						<Button
							onClick={() => {
								confirmChangedPromocode(promocode);
							}}
							isLoading={isLoading}
							className={'w-full p-3'}
						>
							Quero associar a minha conta a {newAffiliate}
						</Button>
					</div>
				</div>
			</Modal>
		</PromoContext.Provider>
	);
};

export const usePromoContext = (): PromoContextType => {
	const context = useContext(PromoContext);
	if (context === undefined) {
		throw new Error('usePromoContext must be used within a PromoProvider');
	}
	return context;
};
