import { useUserProfileStore } from '../../../../store/user';
import { EditNick } from './Edit/Nick';
import { EditEmail } from './Edit/Email';
import { EditPhone } from './Edit/Phone';
import { EditPassword } from './Edit/Password';
import { SendDocument } from './Edit/Document';
import { EditPixKey } from './Edit/PixKey';
import { EditWithDraw } from './Edit/EditWithDraw';

export const ProfileInfo = () => {
	return (
		<div className="flex flex-col gap-6 pb-6">
			<EditNick />
			<EditEmail />
			<EditPhone />
			<EditPassword />
			<EditPixKey />
			<EditWithDraw />
			<SendDocument />
		</div>
	);
};
