import { useEffect, useState } from 'react';
import { RiUploadCloud2Line, RiUploadCloudFill } from '@remixicon/react';
import TextInput from '../../../../../components/ui/TextInput';
import Button from '../../../../../components/ui/button';
import Modal from '../../../../../components/Modal/modal';

import { helpButton } from '../../../../../components/ui/helpButton';
import { sentryCapture } from '../../../../../helpers/utils';
import { SentryError } from '../../../../../types/enums';
import { useApi } from '../../../../../hooks/useApi';
import { toast } from 'sonner';
import Toast from '../../../../../components/ui/toast';
import { useTokenStore } from '../../../../../store/token';

interface Props {
	tabsDocuments?: boolean;
	isDocumentFalse?: boolean;
}

export const SendDocument = ({ tabsDocuments, isDocumentFalse }: Props) => {
	const api = useApi();
	const token = useTokenStore((state: any) => state.token);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(false);
	const [files, setFiles] = useState<any>([]);
	const maxFiles = 5;

	useEffect(() => {
		if (!openModal) {
			setFiles([]);
		}
	}, [openModal]);

	const handleFileChange = (e: any) => {
		const newFiles = Array.from(e.target.files).filter(
			(file: any) =>
				file.type.startsWith('image/') ||
				file.type === 'application/pdf'
		);
		if (files.length + newFiles.length > maxFiles) {
			toast(
				<Toast variant="error">
					Você pode enviar até {maxFiles} arquivos.
				</Toast>
			);
			return;
		}
		setFiles([...files, ...newFiles]);
	};

	const fileSize = (size: any) => {
		const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
		return `${(size / Math.pow(1024, i)).toFixed(2)} ${
			['B', 'KB', 'MB', 'GB', 'TB'][i]
		}`;
	};

	const handleUpload = async () => {
		setIsLoading(true);

		try {
			const formData = new FormData();
			files.forEach((file: File) => {
				formData.append('files', file);
			});
			const response = await api.uploadDocuments(formData, token);
			if (!response?.success) {
				throw new Error(response.message);
			}
			setOpenModal(!openModal);
			setFiles([]);
			toast(
				<Toast variant="success">
					{files.length === 1
						? 'Documentos enviado!'
						: 'Documentos enviados!'}
				</Toast>
			);
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'editPixKey', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="flex items-center gap-4">
			{tabsDocuments ? (
				<Button
					onClick={() => setOpenModal(!openModal)}
					className={'w-full h-12'}
				>
					Enviar documentos
				</Button>
			) : !isDocumentFalse ? (
				<div className="bg-error rounded-md flex flex-col items-center px-3.5 py-4 gap-4 w-full lg:justify-between lg:flex-row lg:px-5">
					<p className="text-base text-center text-custom-white font-bahnschrift">
						Faça o envio do seu CPF + Rg ou CNH para liberarmos os
						seus limites de depósito e saque
					</p>

					<Button
						onClick={() => setOpenModal(!openModal)}
						variant="white2"
						className="w-full py-1.5 px-2 lg:w-60"
						textCustom="text-custom-gray1"
					>
						Enviar documento
					</Button>
				</div>
			) : (
				<>
					<div className="flex-1">
						<TextInput
							type="text"
							placeholder="Documentos"
							value={''}
							onChange={() => {}}
							disabled
						/>
					</div>

					<Button
						onClick={() => setOpenModal(!openModal)}
						variant="help"
						className={'w-12 h-12'}
					>
						<RiUploadCloudFill
							size={24}
							color="#3A3D44"
							className={''}
						/>
					</Button>
				</>
			)}

			<Modal
				id="email"
				open={openModal}
				onClose={() => setOpenModal(!openModal)}
			>
				<div className="flex flex-col gap-6">
					<p className="text-2xl font-bold text-left text-custom-gray2 font-bahnschrift">
						Enviar documentos
					</p>

					<div className="flex flex-col gap-0.5">
						<div className="relative rounded-lg bg-custom-gray7">
							<input
								type="file"
								accept="image/png, image/jpeg, image/jpg, image/gif, application/pdf"
								multiple
								onChange={handleFileChange}
								className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
							/>
							<div className="flex flex-row items-center justify-between py-4 px-4">
								<p className="text-sm text-left text-custom-gray2 font-bahnschrift">
									Selecione os arquivos a enviar
								</p>

								<RiUploadCloud2Line
									size={20}
									color="#3A3D44"
									className="w-18 h-18"
								/>
							</div>
						</div>
						<div className="flex grid-cols-2 gap-2 justify-between ">
							<div className=" flex flex-col gap-1 w-3/5">
								{files.map(
									(file: { name: string }, index: number) => (
										<p
											key={index}
											className="text-xs text-left text-custom-gray4 font-bahnschrift"
										>
											{file.name}
										</p>
									)
								)}
							</div>

							<p className="text-xs text-right  text-custom-gray4 w-1/4 font-bahnschrift">
								{files.length}/{maxFiles} (
								{fileSize(
									files.reduce(
										(acc: any, file: { size: any }) =>
											acc + file.size,
										0
									)
								)}
								)
							</p>
						</div>
					</div>

					<Button
						onClick={handleUpload}
						isLoading={isLoading}
						className="px-3.5 py-3 font-bahnschrift text-center"
						textCustom="text-sm"
					>
						Enviar
					</Button>

					<div className="flex flex-row justify-between items-center">
						{helpButton()}
					</div>
				</div>
			</Modal>
		</div>
	);
};
