interface PropsIcon {
	classname?: string;
}

export const Withdraw2 = ({ classname }: PropsIcon) => {
	return (
		<svg
			width={8}
			height={16}
			viewBox="0 0 8 16"
			fill="none"
			className={classname}
			xmlns="http://www.w3.org/2000/svg"
			preserveAspectRatio="none"
		>
			<path
				d="M-1.29233e-06 0.99875C-1.34041e-06 0.44875 0.449998 -0.00124961 0.999999 -0.00124966L7 -0.00125018C7.55 -0.00125023 8 0.44875 8 0.99875C8 1.54875 7.55 1.99875 7 1.99875L0.999999 1.99875C0.449999 1.99875 -1.24424e-06 1.54875 -1.29233e-06 0.99875ZM5 12.0088L5 4.99875C5 4.44875 4.55 3.99875 4 3.99875C3.45 3.99875 3 4.44875 3 4.99875L3 12.0088L1.21 12.0088C0.76 12.0088 0.539999 12.5488 0.859999 12.8588L3.65 15.6388C3.85 15.8288 4.16 15.8288 4.36 15.6388L7.14 12.8588C7.46 12.5488 7.24 12.0088 6.79 12.0088L5 12.0088Z"
				fill="#6B6E75"
			/>
		</svg>
	);
};
