import React from 'react';
import { getVariant, variantProp } from '../../helpers/variants';
import { RiArrowLeftSLine, RiRestartLine } from '@remixicon/react';
import { useNavigate } from 'react-router-dom';

type ButtonProps = {
	className?: string;
	classNameIcon?: string;
};

const ButtonBack = ({ className, classNameIcon }: ButtonProps) => {
	const navigate = useNavigate();
	return (
		<button onClick={() => navigate(-1)} className={className}>
			<RiArrowLeftSLine className={classNameIcon} />
		</button>
	);
};

export default ButtonBack;
