import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';

const AppUrlListener: React.FC<any> = () => {
	let navigate = useNavigate();
	useEffect(() => {
		App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
			const domain = 'futpro.mauriciosouza.com.br';
			const pathArray = event.url.split(domain);
			const appPath = pathArray.pop();
			if (appPath) {
				navigate(appPath);
			}
		});
	}, []);

	return null;
};

export default AppUrlListener;
