import React from 'react';
import { getVariantToast, variantToastProp } from '../../helpers/variants';

type ToastProps = {
	children: React.ReactNode;
	variant: variantToastProp;
};

const Toast = ({ children, variant }: ToastProps) => {
	return (
		<div className={`${getVariantToast(variant || '')}`}>
			<p className="text-sm font-semibold text-center text-custom-white font-bahnschrift">
				{children}
			</p>
		</div>
	);
};

export default Toast;
